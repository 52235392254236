import React from 'react';
import { css } from 'styled-components';
import { Button, ButtonProps, color } from '@evidation/ui';
import {
  primaryFontFamily,
} from '../../../utils/brandHelpers';
import useBrand from '../../../hooks/useBrand';

export const ThemedButton = (props: ButtonProps) => {
  const { variant = 'primary', ...rest } = props;
  const { branding = {} } = useBrand();

  const determineVariantBasedOnTheme = () => {
    if (branding && branding?.brand_name?.toLowerCase() === 'ppmi') {
      return `ppmi-${variant}`;
    }
    return variant;
  };

  return (
    <Button
      variant={determineVariantBasedOnTheme()}
      variants={{
        'ppmi-primary': css`
          color: #fff;
          width: 100%;
          justify-content: center;
          font-family: ${primaryFontFamily(
            { ...branding, brand_name: branding?.brand_name || '' },
            { fontWeight: 'semibold' },
          )};
          background-color: ${branding?.branding_json?.color_3 || color.brand.secondary};
          &:hover {
            background-color: ${branding?.branding_json?.color_3 ||
            color.brand.secondary};
            color: #fff;
          }
          &:focus {
            background-color: ${branding?.branding_json?.color_3 ||
            color.brand.secondary};
            border: 1px solid ${color.borders.focus};
          }
          &:active {
            background-color: ${branding?.branding_json?.color_3 ||
            color.brand.secondary};
          }
          &:disabled {
            background: ${color.states.disabled};
            color: ${color.text.disabled};
          }
        `,
        'ppmi-light': css`
          background-color: ${branding?.branding_json?.color_3 ||
          color.brand.secondary};
          font-family: ${primaryFontFamily(
            { ...branding, brand_name: branding?.brand_name || '' },
            {
              fontWeight: 'semibold',
            },
          )};
          color: #fff;
        `,
        'ppmi-secondary': css`
          color: ${branding?.branding_json?.color_10 || color.text.default};
          font-family: ${primaryFontFamily(
            { ...branding, brand_name: branding?.brand_name || '' },
            { fontWeight: 'semibold' },
          )};
          background-color: transparent;
          &:hover {
            background-color: ${branding?.branding_json?.color_3 ||
            color.brand.secondary};
            color: #fff;
          }
          &:focus {
            background-color: ${branding?.branding_json?.color_3 ||
            color.brand.secondary};
            border: 1px solid ${color.borders.focus};
          }
          &:active {
            background-color: ${branding?.branding_json?.color_3 ||
            color.brand.secondary};
          }
          &:disabled {
            background: ${color.states.disabled};
            color: ${color.text.disabled};
          }
        `,
      }}
      {...rest}
    />
  );
};
