// @ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import _ from 'lodash';
import { NavLinkDiv } from './NavLink.styles';
import api, { CookieName } from '../services';
import { actions } from '../redux';
import { connect } from 'react-redux';
import { getUrlParams, getQueryString } from '../utils';
import { storeEnrollmentLocalStorage } from '../services';
import logger from '../utils/errorLogger';
import { getUserAgentDetails, getVisitorId } from '../utils/fingerprint';
import { useTranslation } from 'react-i18next';
import { AWS_COGNITO_PROVIDER } from '../constants';

export const cookie = `${CookieName}_utm`;

const utmParams = [
  'referrer',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_targeting',
  'utm_term',
  'utm_campaign',
];

/**
 * @description When function is run it checks the current URL for the presence of
 * query parameters. If they are present it will filter out specific keys in the
 * list of parameters and their values. Then store the parameters as a Cookie with
 * and expiration of one day.
 **/
export const storeUtmParams = () => {
  const urlParams = getUrlParams(window.location.search);

  const filteredParams = utmParams.reduce((result, param) => {
    if (urlParams[param]) {
      result[param] = urlParams[param];
    }
    return result;
  }, {});

  if (Object.keys(filteredParams).length > 0) {
    api.cookie.set(cookie, JSON.stringify(filteredParams), {
      expires: 1,
    });
  }
};

/**
 * Whether the a user has created a third party account and we need to
 * trigger the login process. We test this by checking the relevant
 * query parameter.
 *
 * @param {string} authProvider - The authentication provider we're
 *   using for the study
 */
export const wasThirdPartyAccountCreated = (authProvider) => {
  return (
    authProvider &&
    getQueryString('idp_login').toLowerCase() === authProvider.toLowerCase()
  );
};

/**
 * @description Component used to start Cognito login process. Uses a button
 * to allow a user to redirect and finish third party logins.
 *
 * @returns {JSX.Element}
 **/
const CognitoLogin = ({
  getMeta,
  getUser,
  meta: { slug, authentication = {} },
  setIsLoading,
  addError,
  padding,
}) => {
  const { t } = useTranslation();

  const redirectSignIn = `${window.location.origin}/${slug}/`;
  const {
    cognito_client_id: clientId,
    cognito_domain: domain,
    cognito_region: region,
    cognito_userpool_id: userPoolId,
    response_type: responseType,
    scope,
  } = authentication.details;

  Amplify.configure({
    Auth: {
      region,
      userPoolId,
      clientId,
      userPoolWebClientId: clientId,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      oauth: {
        domain,
        scope,
        redirectSignIn,
        redirectSignOut: redirectSignIn,
        responseType,
      },
    },
  });

  const [token, setToken] = useState({});
  const [userInfo, setUserInfo] = useState({});

  const handleLogin = useCallback(() => {
    setIsLoading(true);
    if (authentication.provider.toLocaleLowerCase() === AWS_COGNITO_PROVIDER) {
      Auth.federatedSignIn();
    } else {
      Auth.federatedSignIn({
        provider: authentication.provider,
      });
    }
  }, [authentication.provider, setIsLoading]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setIsLoading(true);
          const token = {
            access_token: data.signInUserSession.accessToken.jwtToken,
            id_token: data.signInUserSession.idToken.jwtToken,
          };
          setToken(token);
          break;
        case 'signIn_failure':
          console.error('There was a problem logging you in.');
          setIsLoading(false);
          break;
        default:
          break;
      }
    });
    storeUtmParams();

    // This handles the case if a user has created a third party account,
    // it will trigger the login process after account creation.
    if (wasThirdPartyAccountCreated(authentication.provider)) {
      handleLogin();
    }
  }, [authentication.provider, handleLogin, setIsLoading]);

  useEffect(() => {
    const getTriageToken = async () => {
      const userAgentDetails = getUserAgentDetails();
      const visitorId = await getVisitorId();
      const utmCookie = api.cookie.get(cookie);
      const analytics = utmCookie ? JSON.parse(utmCookie) : {};
      api
        .cognito(slug, {
          ...token,
          analytics,
          meta: {
            userAgent: userAgentDetails,
            visitorId,
          },
        })
        .then(({ data }) => {
          setUserInfo(data);
        })
        .catch((error) => {
          logger.error(error);
          const message =
            error.response?.data?.error ?? t('components.error.defaultError');
          addError(new Error(message));
          setIsLoading(false);
        });
    };

    if (!_.isEmpty(token)) {
      getTriageToken();
    }
  }, [slug, token, setUserInfo, setIsLoading, addError, t]);

  useEffect(() => {
    let isCancelled = false;
    const loginUser = async ({
      participant_auth_token,
      enrollment_identifier,
    }) => {
      if (!isCancelled) {
        getMeta(participant_auth_token, enrollment_identifier);
        const { payload, error = false } = await getUser(
          enrollment_identifier,
          participant_auth_token,
        );
        if (!error) {
          storeEnrollmentLocalStorage(payload);
        }
        setIsLoading(false);
      }
    };

    if (
      _.has(userInfo, 'participant_auth_token') &&
      _.has(userInfo, 'enrollment_identifier')
    ) {
      loginUser(userInfo);
    }

    return () => {
      isCancelled = true;
    };
  }, [userInfo, getMeta, getUser, setIsLoading]);

  return (
    <NavLinkDiv onClick={handleLogin} padding={padding}>
      LOGIN
    </NavLinkDiv>
  );
};

export default connect(
  ({ meta }) => ({ meta }),
  (dispatch) => ({
    getMeta: (auth_token, enrollment_identifier) =>
      dispatch(actions.getMeta(auth_token, enrollment_identifier)),
    getUser: (enrollment_id, auth_token) =>
      dispatch(actions.getUser(enrollment_id, auth_token)),
    addError: (error) => dispatch(actions.addError(error)),
  }),
)(CognitoLogin);
