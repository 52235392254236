export type SignIn = {
  acm_id?: number;
  compensation?: Array<any>;
  connected_apps: Array<any>;
  email: string;
  enrollment_identifier?: string;
  errors?: Array<any>;
  external_ids?: Array<string>;
  fb_event?: boolean;
  inactivity_timeout?: number;
  informed_consents?: Array<any>;
  layout: string;
  locale?: string;
  messages: Array<any>;
  nodes: Array<Node>;
  now?: string;
  participant_auth_token: string;
  phone_number: string | null;
  pinned_contributions: Array<unknown>;
  sns_endpoint_id?: Array<string>;
  status: string;
  tags: Array<string>;
};

export const defaultSingInValue: SignIn = {
  connected_apps: [],
  email: '',
  messages: [],
  participant_auth_token: '',
  layout: '',
  nodes: [],
  phone_number: null,
  pinned_contributions: [],
  status: '',
  tags: [],
};

export type Node = {
  slug: string;
  type: string;
  progress?: number;
  content: Content;
  status: string;
};

export const defaultNodeContent: Content = {
  type: '',
  form: [],
  title: '',
  available_apps: [],
};

export const defaultNodeValue: Node = {
  slug: '',
  type: '',
  content: defaultNodeContent,
  status: '',
};

type AvailableApp = {
  begin_url: string;
  name: string;
  icon: string;
  [key: string]: any;
};

type Content = {
  type: string;
  form: Array<Form>;
  title: string;
  completion_message?: string;
  description?: string;
  arms?: Array<any>;
  is_root?: boolean;
  on_island?: boolean;
  on_archipelago?: boolean;
  display_priority?: number;
  faq_array?: Array<Faqarray>;
  hero_title?: string;
  faq_heading?: string;
  hero_button?: string;
  hero_content?: string;
  how_you_help_steps?: Array<{ step: string }>;
  how_you_help_heading?: string;
  faq_enroll_form_heading?: string;
  display_group_id?: string;
  open_in_new_window?: boolean;
  begin_url?: string;
  action_title?: string;
  available_apps?: AvailableApp[];
  header_image_url?: string;
  footer_image_url?: string;
  margin_top?: number;
  margin_bottom?: number;
  reauth_pages?: unknown[];
  optional?: boolean;
};

export type Form = {
  mask?: number;
  name: string;
  label: string;
  required?: boolean;
  interface: string;
  noneLabel?: number;
  inputStyle?: string;
  accepts_data?: boolean;
  possible_answers?: Array<Possibleanswer>;
  value?: any;
};

interface Faqarray {
  answer: string;
  question: string;
}

type Possibleanswer = {
  label: string;
  answer: string;
};

export type DisplayGroup = {
  name: string;
  layout: '1-column' | '2-columns';
  enable_progress_tracker_panel?: boolean;
};

export type META = {
  authentication: Authentication;
  brand?: Brand;
  completed_display_group_name: string;
  contact_email?: string;
  contact_modal_heading?: string;
  contact_modal_message?: string;
  contact_phone_number?: string;
  contact_preferred_method?: string;
  customer_service_provider?: string;
  dashboard_header: string;
  default_display_group_name: string;
  display_groups: Array<DisplayGroup>;
  enrollment_appx_time?: number;
  enrollment_closed_content?: string;
  failure?: boolean;
  google_analytics_id?: string;
  i18n_enabled?: boolean;
  is_program?: boolean;
  keywords?: string;
  locale?: string;
  primary_locale?: string;
  recaptcha_version?: string;
  reconsent_modal_body: string;
  reconsent_modal_title: string;
  secondary_locales?: any[];
  self_withdrawal_allowed?: boolean;
  slug: string;
  status?: string;
  study_closed_content?: string;
  study_completion_message: string;
  study_name?: string;
  study_summary?: string;
  userAgent?: UserAgent;
  visitorId: string;
  dashboard_withdrawal_label?: string;
  dashboard_withdrawal_link?: string;
  dashboard_withdrawal_modal_header?: string;
  dashboard_withdrawal_modal_paragragh_1?: string;
  dashboard_withdrawal_modal_paragragh_2?: string;
  dashboard_withdrawal_modal_reason?: string;
  show_informed_consent_download_link?: boolean;
  supported_features?: {
    profile_data?: unknown;
  };
  connected_apps_header?: string;
  connected_apps_description?: string;
  zendesk_api_key?: string;
  screener_progress_bars?: boolean;
};

export type Brand = {
  brand_name: string;
  branding_json?: {
    company_name: string;
    company_url: string;
    email_domain: string;
    page_title: string;
    [key: string]: any;
  };
};

export const metaDefaultValues: META = {
  slug: '',
  study_name: '',
  study_summary: '',
  status: '',
  primary_locale: '',
  authentication: {
    provider: '',
    details: undefined,
  },
  locale: '',
  i18n_enabled: false,
  userAgent: {
    browser: {
      name: '',
      version: 0,
      isMobile: false,
    },
    screenRes: {
      width: 0,
      height: 0,
    },
    timeZone: '',
    os: {
      name: '',
      version: '',
    },
    device: {
      vendor: undefined,
      name: undefined,
    },
  },
  visitorId: '',
  dashboard_header: '',
  display_groups: [],
  default_display_group_name: '',
  completed_display_group_name: '',
  study_completion_message: '',
  reconsent_modal_body: '',
  reconsent_modal_title: '',
};

type UserAgent = {
  browser: Browser;
  screenRes: ScreenRes;
  timeZone: string;
  os: Os;
  device: Device;
};

type Device = {
  vendor?: string | null;
  name?: string | null;
  model?: string | null;
};

type Os = {
  name: string;
  version: string;
};

type ScreenRes = {
  width: number;
  height: number;
};

interface Browser {
  name: string;
  version: number;
  isMobile: boolean;
}

export type Authentication = {
  provider: string;
  details?: AuthenticationDetails;
};

export type AuthenticationDetails = {
  cognito_region: string;
  cognito_userpool_id: string;
  cognito_domain: string;
  cognito_client_id: string;
  scope: string[];
  response_type: string;
  logout: string;
};

export type Compensation = {
  name: string;
  status: string;
  currency: string;
  amount: number;
  earned_amount: number;
};

export type Participant = {
  enrollment_identifier: string;
  participant_auth_token: string;
  status: string;
  compensation: Compensation[];
  pinned_contributions: unknown[];
  nodes: Node[];
  layout: string;
  auth_token_expiration: Date | null;
  connected_apps: Array<any>;
  email: string;
  loading: boolean;
  messages: Array<any>;
  phone_number: string | null;
  preferred_contact_method: string;
  preferred_contact_time: string;
  tags: Array<string>;
  inactivity_timeout?: number;
};
