export type Fonts = {
  [key: string]: {
    [key: string]: Array<string>;
  };
};
export type Theme = {
  branding_json?: {
    fonts?: Fonts;
    [key: string]: any;
  };
  brand_name: string;
};
// TODO: Put these values into the branding object and stop hard-coding them.
const PPMI_FONTS: Fonts = {
  primary: {
    regular: ['SofiaProRegular', 'sans-serif'],
    light: ['SofiaProLight', 'sans-serif'],
    bold: ['SofiaProBold', 'sans-serif'],
    semibold: ['SofiaProSemiBold', 'sans-serif'],
  },
  secondary: {
    regular: ['DomaineTextBold', 'serif'],
  },
};

/**
 * Turns an array of font-family values into a comma-separated list for CSS.
 *
 * @param {string[] | string} fontFamilies - The array of font-family values.
 *   Can also be a string, in which case no transformation is done.
 * @returns {string} - The comma-separated list. If `fontFamilies` is already a
 *   string, returns that as-is.
 */
export const joinFontFamilies = (fontFamilies: Array<string> | string) =>
  Array.isArray(fontFamilies)
    ? fontFamilies
        .map((family) => (/\s+/.test(family) ? `"${family}"` : family))
        .join(', ')
    : fontFamilies;

/**
 * @typedef {'regular'|'bold'|'semibold'|'light'} FontWeight
 */

const ppmiPrimaryFontFamily = ({ fontWeight = 'regular' } = {}) =>
  PPMI_FONTS.primary[fontWeight] ?? PPMI_FONTS.primary.regular;

const brandPrimaryFontFamily = (
  theme: Theme | null = null,
  { fontWeight = 'regular' } = {},
) =>
  theme?.branding_json?.fonts?.primary?.[fontWeight] ??
  theme?.branding_json?.fonts?.primary?.regular ??
  theme?.branding_json?.font_1;

const ppmiSecondaryFontFamily = ({ fontWeight = 'regular' } = {}) =>
  PPMI_FONTS.secondary[fontWeight] ?? PPMI_FONTS.secondary.regular;

const brandSecondaryFontFamily = (
  theme: Theme | null = null,
  { fontWeight = 'regular' } = {},
) =>
  theme?.branding_json?.fonts?.secondary?.[fontWeight] ??
  theme?.branding_json?.fonts?.secondary?.regular ??
  theme?.branding_json?.font_2;

/**
 * Returns the font-family value for the "primary" font for the brand in the
 * given theme object.
 *
 * @param {object} theme The "theme" object passed to styled components.
 * @param {object} [options]
 * @param {FontWeight} [options.fontWeight='regular'] Get the font-family for
 *   this font weight. Defaults to "regular".
 * @returns {string} A value for the font-family CSS property.
 */
export const primaryFontFamily = (
  theme: Theme | null = null,
  { fontWeight = 'regular' } = {},
) =>
  joinFontFamilies(
    (theme?.brand_name === 'PPMI'
      ? ppmiPrimaryFontFamily({ fontWeight })
      : brandPrimaryFontFamily(theme, { fontWeight })) ?? 'Adamina, serif',
  );

/**
 * Same as {@link primaryFontFamily} but for the "secondary" font.
 *
 * @param {object} theme The "theme" object passed to styled components.
 * @param {object} [options]
 * @param {FontWeight} [options.fontWeight='regular'] Get the font-family for
 *   this font weight. Defaults to "regular".
 * @returns {string} A value for the font-family CSS property.
 */
export const secondaryFontFamily = (
  theme: Theme | null = null,
  { fontWeight = 'regular' } = {},
) =>
  joinFontFamilies(
    (theme?.brand_name === 'PPMI'
      ? ppmiSecondaryFontFamily({ fontWeight })
      : brandSecondaryFontFamily(theme, { fontWeight })) ?? 'Heebo, sans-serif',
  );
