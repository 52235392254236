import { css } from 'styled-components';
import SourceSerifProBold from './SourceSerifPro-Bold.ttf';
import PublicSansBold from './PublicSans-Bold.ttf';
import PublicSansRegular from './PublicSans-Regular.ttf';
import PublicSansLight from './PublicSans-Light.ttf';
import PublicSansSemiBold from './PublicSans-SemiBold.ttf';

const Fonts = css`
  @font-face {
    font-family: 'Source Serif Pro';
    font-weight: 700;
    src: url('${SourceSerifProBold}') format('opentype');
  }

  @font-face {
    font-family: 'Public Sans';
    font-weight: 700;
    src: url(${PublicSansBold}) format('opentype');
  }

  @font-face {
    font-family: 'Public Sans';
    font-weight: 600;
    src: url(${PublicSansSemiBold}) format('opentype');
  }

  @font-face {
    font-family: 'Public Sans';
    font-weight: 400;
    src: url(${PublicSansRegular}) format('opentype');
  }

  @font-face {
    font-family: 'Public Sans';
    font-weight: 300;
    src: url(${PublicSansLight}) format('opentype');
  }
`;

export default Fonts;
