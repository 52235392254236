import styled from 'styled-components';

export const AuthFormContainer = styled.div`
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & > form {
    margin: auto 0;
  }
`;
