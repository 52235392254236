// @ts-nocheck
import Cookie from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { useTranslationWithPrefix } from '../../hooks';
import { NavLinkDiv, NavLinkStyle } from '../NavLink.styles';

export const TEXT_SIZE_COOKIE_NAME = 'text-size';
const DEFAULT_TEXT_SIZE = 16;

const getStoredTextSize = () =>
  parseInt(Cookie.get(TEXT_SIZE_COOKIE_NAME), 10) || null;

const Container = styled.div`
  position: relative;
`;

const DropdownItems = styled.ul`
  position: absolute;
  z-index: 1000;
  list-style-type: none;
  margin: 0;

  background-color: white;
  border: 1px solid #cccccc;
  padding: 0.5em;

  li {
    ${NavLinkStyle}
    padding: 0.5em;
    .label {
      text-transform: uppercase;
    }
  }
`;

export default function TextSizeSelect() {
  const { t } = useTranslationWithPrefix('components.header.textSizeSelect');

  const [selectedSize, setSelectedSize] = useState(
    getStoredTextSize() || DEFAULT_TEXT_SIZE,
  );
  const [dropdownState, setDropdownState] = useState({
    isOpen: false,
  });
  const navLinkRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownState.isOpen) {
      // Close the dropdown when clicking outside of it.
      // TODO: Use the new `useClickOutside` hook available in SPP-129.
      const listener = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          closeDropdown();
        }
      };
      document.addEventListener('click', listener);
      return () => {
        document.removeEventListener('click', listener);
      };
    }
  }, [dropdownState.isOpen]);

  useEffect(() => {
    Cookie.set(TEXT_SIZE_COOKIE_NAME, selectedSize, { expires: 365 });
  }, [selectedSize]);

  const openDropdown = () => {
    setDropdownState({
      isOpen: true,
    });
  };

  const closeDropdown = () => {
    setDropdownState({
      isOpen: false,
    });
  };

  const selectSize = (size) => {
    setSelectedSize(size);
    closeDropdown();
  };

  const optionProps = (size) => ({
    onClick: () => selectSize(size),
    className: selectedSize === size ? 'active' : '',
    'aria-current': selectedSize === size,
  });

  return (
    <Container>
      <NavLinkDiv ref={navLinkRef} onClick={() => openDropdown()}>
        {t('aPlus')}
      </NavLinkDiv>
      {dropdownState.isOpen && (
        <DropdownItems ref={dropdownRef}>
          <li {...optionProps(16)}>
            <span className="label">{t('small')}</span> &ndash; 16{t('pt')}
          </li>
          <li {...optionProps(18)}>
            <span className="label">{t('medium')}</span> &ndash; 18{t('pt')}
          </li>
          <li {...optionProps(20)}>
            <span className="label">{t('large')}</span> &ndash; 20{t('pt')}
          </li>
        </DropdownItems>
      )}

      <Helmet>
        <style>
          {`
            html {
              font-size: ${selectedSize}px;
            }
          `}
        </style>
      </Helmet>
    </Container>
  );
}
