import { css } from 'styled-components';
import DomaineTextBold from './DomaineText-Bold.otf';
import SofiaProBold from './SofiaProBold.otf';
import SofiaProRegular from './SofiaProRegular.otf';
import SofiaProLight from './SofiaProLight.otf';
import SofiaProSemiBold from './SofiaProSemiBold.otf';

const Fonts = css`
  @font-face {
    font-family: 'DomaineTextBold';
    font-weight: 700;
    src: url('${DomaineTextBold}') format('opentype');
  }

  @font-face {
    font-family: 'SofiaProRegular';
    font-weight: 400;
    src: url(${SofiaProRegular}) format('opentype');
  }

  @font-face {
    font-family: 'SofiaProLight';
    font-weight: 400;
    src: url(${SofiaProLight}) format('opentype');
  }

  @font-face {
    font-family: 'SofiaProBold';
    font-weight: 700;
    src: url(${SofiaProBold}) format('opentype');
  }

  @font-face {
    font-family: 'SofiaProSemiBold';
    font-weight: 700;
    src: url(${SofiaProSemiBold}) format('opentype');
  }
`;

export default Fonts;
