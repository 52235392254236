import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Error } from '../../routes/ehr/components/styles';
import { formatPhone } from '../../utils';
import { useTranslationWithPrefix } from '../../hooks';
import Modal from '../styledModal';
// It is necessary to generate and include the types for the @types/evidation__form-elements library
//@ts-expect-error
import { TextInput } from '@evidation/form-elements';
import { noop } from 'lodash';
import ContactModal from '../contactModal';
import { Spacer } from '@evidation/ui';
import { StyledButton } from '../BillboardNavigation';
import { ThemedButton } from '../../routes/settings/components/themedButton';

const StyledText = styled(TextInput)`
  min-width: 150px;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const ResendButton = styled.button`
  background: none;
  font-size: inherit;
  white-space: nowrap;
  text-decoration: underline !important;
  color: #06466d;
  cursor: pointer;
`;

export type VerifyPhoneProps = {
  phone: string;
  isLoading: boolean;
  error?: string | null;
  onClose: () => void;
  onDone: (otp: string) => void;
  successMessage: string | null;
};

const VerifyPhoneModal: React.FC<VerifyPhoneProps> = ({
  phone,
  isLoading,
  error,
  onClose,
  onDone,
  successMessage = null,
}) => {
  const [otpValue, setOtpValue] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showResend, setShowResend] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const { t } = useTranslationWithPrefix('routes.enrollment.verifyPhone');

  const text = {
    title: t('title'),
    confirmText: t('confirmText'),
    placeHolder: t('placeHolder'),
    messageSent: t('messageSent'),
    resend: t('resend'),
    verifyOtp: t('verifyOtp'),
    havingIssues: t('havingIssues'),
    contactUs: t('contactUs'),
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowResend(true), 60000);

    return () => {
      clearTimeout(timer);
    };
  }, [resendCount]);

  useEffect(() => {
    setErrorMessage(error || '');
  }, [error]);

  const handleOnDone = () => {
    onDone(otpValue);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberRegex = /^\d{0,6}$/;
    const value = e.target.value;
    const isValidNumber = numberRegex.test(value);
    setErrorMessage('');

    if (value === '' || isValidNumber) {
      setOtpValue(value);
      setDisableButton(value.length < 6);
    }
  };

  const handleResend = () => {
    setResendCount(resendCount + 1);
    setShowResend(false);
    onDone('');
    setErrorMessage('');
    setOtpValue('');
  };

  return (
    <Modal
      header={text.title}
      isOpen={true}
      closeModal={onClose}
      onBackgroundClick={noop}
    >
      {!successMessage && (
        <>
          <p>
            {text.confirmText} {formatPhone(phone)}
          </p>
          <StyledText
            name="otp"
            id="otp"
            value={otpValue}
            onChange={handleOnChange}
            placeholder={text.placeHolder}
          />
          {errorMessage && <Error>{errorMessage}</Error>}
          <StyledButton
            isLoading={isLoading}
            buttonStyle="achievement"
            disabled={disableButton || isLoading}
            id="t-otp-submit_button"
            onClick={handleOnDone}
          >
            {text.verifyOtp}
          </StyledButton>
          {showResend && (
            <p>
              {text.messageSent}
              <ResendButton onClick={handleResend}>{text.resend}</ResendButton>
            </p>
          )}
        </>
      )}
      {successMessage && (
        <div data-e2e-test-id="success_otp">
          <p data-e2e-test-id="success_otp_message">{successMessage}</p>
          <Spacer size={20} />
          <p className="success">{text.havingIssues}</p>
          <ContactModal>
            {({ showModal }: { showModal: () => void }) => (
              <ThemedButton onClick={showModal} variant="secondary">
                {text.contactUs}
              </ThemedButton>
            )}
          </ContactModal>
        </div>
      )}
    </Modal>
  );
};

export default VerifyPhoneModal;
