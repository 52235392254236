import styled, { css } from 'styled-components';

import {
  primaryFontFamily,
  secondaryFontFamily,
} from '../../utils/brandHelpers';
import RaisedHands from './RaisedHands';

const isPPMI = (theme: { brand_name: string }) => theme.brand_name === 'PPMI';

export const StyledRaisedHands = styled(RaisedHands)`
  & path {
    fill: ${({ theme }) => (isPPMI(theme) ? '#04545e' : '#2a3f53')};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 25px 0px 35px;

  @media (max-width: 450px) {
    padding: 25px 25px 0px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  padding-top: 20px;
  letter-spacing: 0.5px;
  font-family: ${({ theme }) => primaryFontFamily(theme)};
  font-style: normal;
  font-weight: 400;
  font-size: ${36 / 16}rem;
  color: #2a3f53;

  ${({ theme }) => {
    if (isPPMI(theme)) {
      return css`
        && {
          font-family: ${secondaryFontFamily(theme)};
          font-style: normal;
          font-weight: 700;
          font-size: ${30 / 16}rem;
          color: #04545e;
        }
      `;
    }
  }}
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  padding: 0;
  transition: all 0.3 ease-out;

  :hover svg {
    opacity: 0.8;
  }
`;

export const BodyContainer = styled.div`
  padding: 30px 35px 80px;

  @media (max-width: 450px) {
    padding: 30px 25px 80px;
  }
`;

export const Paragraph = styled.p`
  margin: 0px;
  font-family: ${({ theme }) => secondaryFontFamily(theme)};
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #2a3f53;

  &:not(:first-of-type) {
    margin-top: 8px;
  }

  ${({ theme }) => {
    if (isPPMI(theme)) {
      return css`
        && {
          font-family: ${primaryFontFamily(theme)};
          color: #333333;
        }
      `;
    }
  }}
`;
