// @ts-nocheck
import React, { Component } from 'react';

import { AUTH_TYPES } from '../../../constants';
import ErrorMessage from '../../../components/ErrorMessage';
import FacebookLogin from 'react-facebook-login';
import { ThirdPartyLoginDiv } from './index';
import PropTypes from 'prop-types';

class ThirdPartyLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: this.props.message,
    };

    this.processFacebookResponse = this.processFacebookResponse.bind(this);
    this.onFailure = this.onFailure.bind(this);
  }

  processFacebookResponse(resp) {
    // resp.email should always be present if login is successful
    // since Achievement won't allow FB signup without email

    // case when the resp is not valid
    if (!resp.id || !resp.email || !resp.accessToken) {
      this.setState({
        message:
          'Facebook login is currently unavailable. Please try again in a few minutes.',
      });
      return;
    }

    // case when user try to enroll a study with one email but signed up
    // on Achievement with FB with another email
    if (this.props.email && this.props.email !== resp.email) {
      this.setState({
        message:
          'Facebook login email must match email address used to check eligibility.',
      });
      return;
    }

    const payload = {
      third_party_auth: {
        type: AUTH_TYPES.facebook,
        uid: resp.id,
        access_token: resp.accessToken,
        // token_expires_at: resp.expiresIn, // in seconds
        // currently Portal does not expire FB access token, and if in future they do,
        // the token_expire_at need to be formatted according to what Portal needs
      },
    };

    this.setState({ message: '' }, () => this.props.submit(payload));
  }

  onFailure(errResponse) {
    // does nothing if user closes FB login window
    if (errResponse.status === 'not_authorized') return;
    if (window.env.NODE_ENV !== 'production') {
      console.error(errResponse);
    } else {
      this.setState({
        message:
          'Facebook login unavailable. Please log in with your Achievement email and password.',
      });
    }
  }

  render() {
    const { facebookAppId, buttonText = null } = this.props;
    const { message } = this.state;
    const messageEle =
      !message || message === 'or' ? (
        <p>{message}</p>
      ) : (
        <ErrorMessage message={this.state.message} />
      );

    return (
      <ThirdPartyLoginDiv>
        {messageEle}
        <FacebookLogin
          appId={facebookAppId}
          fields="email,gender,name,location"
          scope="email,user_location"
          callback={this.processFacebookResponse}
          reAuthenticate={true}
          onFailure={this.onFailure}
          disableMobileRedirect={true}
          textButton={buttonText || 'Login with Facebook'}
          icon={
            <img
              src={`https://s3.amazonaws.com/assets.myachievement.com/study/facebook_logo_white_29x29.png`}
              alt=""
            />
          }
        />
      </ThirdPartyLoginDiv>
    );
  }
}

ThirdPartyLogin.propTypes = {
  facebookAppId: PropTypes.string.isRequired,
};

export default ThirdPartyLogin;
