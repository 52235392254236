// Here we control ALL of the languages the app can be shown in , from the front end. For now these are statically translated
// Note: The back end can send whatever languages it wants but we need to add them here as well.
import { enUS, esUS } from '../i18n';

export const availableLanguages = {
  en: { translation: enUS },
  en_US: { translation: enUS },
  es: { translation: esUS },
  es_US: { translation: esUS },
};
