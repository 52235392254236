// @ts-nocheck
import styled, { css } from 'styled-components';
import { StyledButton as BillboardNavigationButton } from '../../../components/BillboardNavigation';

export const GrayBanner = styled.div`
  padding: 20px;
  background-color: #efefef;

  p {
    margin-bottom: 0;
  }
`;

export const FindContainer = styled.div`
  input {
    width: 100% !important;
  }

  input:focus {
    border: 0 !important;
    box-shadow: none !important;
  }
`;

FindContainer.Filter = styled.div`
  width: 33%;
  min-width: 200px;
  margin-bottom: 15px;
`;

export const ProviderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 14px;
  background-color: #fcfcfc;
  border: 1px solid #e6e6e6;
  color: #000;

  &:hover {
    background-color: #e2ecf6;
  }
`;

ProviderContainer.Left = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
`;

ProviderContainer.Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
// findPage.js
export const StyledLink = styled.a`
  color: #fff;
  background: #06466d;
  border-radius: 3px;
  padding: 15px 30px;
  text-decoration: none;
`;
// connectedPage.js
export const ListLabel = styled.h3`
  margin: 15px 0 10px !important;
`;

export const ButtonContainer = styled.div`
  margin: 10px 0 15px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  span {
    width: 50%;
  }

  span:first-child {
    padding-right: 10px;
  }
`;

ButtonContainer.Button = styled(BillboardNavigationButton)`
  ${(props) =>
    props.buttonStyle === 'secondary' &&
    css`
      background: linear-gradient(-180deg, #ffffff 0%, #fcfcfc 100%) !important;
      color: #111 !important;
      border: 1px solid #e3e3e3 !important;
      &:active {
        background: linear-gradient(0deg, #ffffff 0%, #fcfcfc 100%) !important;
      }
      &:disabled {
        color: #b3b3b3 !important;
        background: linear-gradient(
          -180deg,
          #f7f7f7 0%,
          #f5f5f5 100%
        ) !important;
      }
      &:hover {
        cursor: pointer !important;
        background: linear-gradient(
          -180deg,
          #f7f7f7 0%,
          #f5f5f5 100%
        ) !important;
      }
    `};
  height: 60px;
`;

export const Connected = styled.div`
  background: #22a432;
  border-radius: 3px;
  padding: 5px 9px;
  color: #fff;
`;

export const Error = styled.p`
  color: #f00;
`;
// introPage.js
export const StyledButton = styled(BillboardNavigationButton)`
  margin-top: 50px;
  margin-bottom: 75px;
`;
