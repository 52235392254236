import { getQueryString } from "../utils";

const queryStringToSearch = 'locale_override';

/**
 * @description custom hook Needed to let the API know we have received a `locale_override` query param.
 * Sends back the value of the `locale_override` query string.
 * @returns {string}
 */
export const useSetLocaleOverride = () => {
  const queryString = getQueryString(queryStringToSearch);
  return queryString;
};
