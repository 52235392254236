// @ts-nocheck
import React, { Component } from 'react';

import BasicPageWrapper from '../../components/basicPageWrapper';
import ContactModal from '../../components/contactModal';
import Button from '../../components/button';
import { withTranslation } from 'react-i18next';

class FourOhFour extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.contact_us_label = t('components.common.continue');
    this.greetings_label = t('components.fourOhFour.greetings');
    this.contact_support_label = t('components.fourOhFour.contactSupport');
    this.title_text = t('components.fourOhFour.titleText');
  }
  render() {
    return (
      <BasicPageWrapper title={this.title_text}>
        <div>
          <p>{this.greetings_label}</p>
          <p>{this.contact_support_label}</p>
          <ContactModal>
            {({ showModal }) => (
              <Button
                onClick={showModal}
                secondary
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                {this.contact_us_label}
              </Button>
            )}
          </ContactModal>
        </div>
      </BasicPageWrapper>
    );
  }
}

export default withTranslation()(FourOhFour);
