import _ from 'lodash';
import { AnyAction } from 'redux';
import { LandingActionType, MetaActionType } from '../../types/actions';
import { META, metaDefaultValues } from '../../types/api';

const defaultMetaState: META = metaDefaultValues;

export const meta = (
  state: META = defaultMetaState,
  { type, payload }: AnyAction,
): META => {
  switch (type) {
    case MetaActionType.RECEIVE:
      return { ...state, ...payload };
    case MetaActionType.FAILURE:
      return { ...state, failure: true };
    case LandingActionType.RECEIVE:
      return { ...state, ..._.omit(payload, ['nodes', 'layout']) };
    default:
      return state;
  }
};
