import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  @media (max-width: 774px) {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const Bar = styled.div`
  background-color: #e2e2e2;
  height: 16px;
  overflow: hidden;
  width: 100%;

  @media (min-width: 775px) {
    border-radius: 8px;
  }
`;

const Fill = styled.div<{ progress: number }>`
  background-color: #35a76e;
  color: white;
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  transition: 0.3s ease;
  width: ${(props) => `${props.progress || 1}%`};

  min-width: 4%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 775px) {
    border-radius: 8px;
  }

  @media (max-width: 425px) {
    min-width: 6%;
  }
`;

const Description = styled.div`
  display: none;
  margin-top: 20px;
  text-align: center;

  @media (min-width: 775px) {
    display: block;
  }
`;

const Text = styled.div`
  margin: 0;
  font-size: 85%;
`;

type ProgressProps = {
  progress: number;
  enrollmentTime?: number;
};

const Progress: React.FC<ProgressProps> = ({ progress, enrollmentTime }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.billboardNavigation.progress',
  });

  // Clamp between 0 and 100 and then round
  progress = Math.round(Math.max(Math.min(progress, 100), 0));

  return (
    <Container>
      <Bar>
        <Fill progress={progress}>{progress}%</Fill>
      </Bar>
      <Description>
        <Text>
          {t('youHaveCompleted', { percentage: progress })}
          {typeof enrollmentTime !== 'undefined' &&
            ' ' +
              t('onAverageItTakes', {
                quantity: enrollmentTime,
                unit: t('minutes', { count: enrollmentTime }),
              })}
        </Text>
      </Description>
    </Container>
  );
};

export default Progress;
