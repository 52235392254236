// @ts-nocheck
import {
  BigBlueButton,
  ContactUsLink,
  DashboardLink,
  FaqLink,
  LanguagePicker,
  LogInLink,
  LogOutLink,
  LogoLink,
  SettingsLink,
  StyledNavLink,
  mobileOnly,
} from './headerLinks';
import React, { Component } from 'react';
import { canLogin, canLogout, isEnrolled } from '../../utils';
import {
  EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
  STUDY_STATUSES_ENROLLMENT_CLOSED,
} from '../../constants';
import CheckEligibilityModal from '../../routes/education/components/checkEligibilityModal';
import ContactModal from '../contactModal';
import Hamburger from '../hamburger';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  LandingSidePadding,
  LandingPadding,
  LandingPageWidth,
} from '../../routes/education/components';
import { Spacer } from '..';
import Stickyfill from 'stickyfilljs';
import CognitoLogin from '../cognito';
import { simpleUserLogout } from '../../utils/simpleUserLogout';
import { useTranslated } from '../../hooks';
import { withTranslation } from 'react-i18next';
import TextSizeSelect from './textSize';
import { actions } from '../../redux';
import { Flex } from '@evidation/ui';

const mobileMaxWidth = `800px`;
const desktopMinWidth = `801px`;

const HeaderButton = styled(BigBlueButton)`
  height: 30px;
  padding: 1px 20px;
  text-transform: uppercase;
  border: 0 !important;
  font-size: ${14 / 16}rem;
`;

export const DesktopHeaderButton = styled(BigBlueButton)`
  height: 41px;
  padding: 7px 35px;
  text-transform: uppercase;
  border: 0 !important;
  font-size: 1rem;
`;

const logoLink = (layout = ``) => {
  switch (layout) {
    case `education`:
    case `follow_up`:
    case `terminal_state`:
      return `/`;
    case `dashboard`:
    case `screener`:
    case `registration`:
    case `informed_consent`:
    default:
      return `/${layout}`;
  }
};

const StickyHeader = styled.header`
  flex: 0 0 auto;
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  top: 0;
  width: 100%;
  z-index: 5;
  background: #fff;
  border-bottom: 1px solid #e3e3e3;

  @media (max-width: ${mobileMaxWidth}) {
    max-height: 100%;
  }
`;

const HeaderContent = styled.div`
  max-width: ${LandingPageWidth};
  margin: auto;
  height: 100%;
  display: flex;
`;

const desktopOnly = `
  @media (max-width: ${mobileMaxWidth}) {
    display: none !important;
  }
`;

const DesktopLinks = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
  justify-content: space-between;

  & > * {
    padding: 0 10px;
  }
  & > :last-child {
    padding: 0;
  }
  ${desktopOnly};
`;

const MobileHeaderEligibilityMaxWidth = '340px';

const MobileHeaderEligibility = styled.div`
  ${mobileOnly};

  @media (max-width: ${MobileHeaderEligibilityMaxWidth}) {
    display: none !important;
  }
`;

const MobileLinks = styled.div`
  ${mobileOnly};
  position: absolute;
  background: #fff;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  padding: ${LandingPadding};
  padding-top: 10px;
  border-bottom: 1px solid #eff2f4;

  & > * {
    align-self: auto;
    padding: 5px 0;
  }
  & > :first-child {
    padding-top: 0;
  }
  & > :last-child {
    padding-bottom: 15px;
  }
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  @media (max-width: ${mobileMaxWidth}) {
    padding: 10px 0;
  }

  & > * {
    display: flex;
  }
  ${(p) =>
    p.noCheckEligibility &&
    css`
      @media (max-width: ${mobileMaxWidth}) {
        display: flex;
        padding: 11px 0;
      }
    `}
`;

const TitleSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  @media (min-width: 500px) and (max-width: ${mobileMaxWidth}) {
    ${(p) => p.isEducation && `padding-left: 54px;`}
  }

  @media (max-width: ${mobileMaxWidth}) {
    ${(p) => p.hasStudyTitle && 'padding-top: 10px;'}
  }

  @media (max-width: ${mobileMaxWidth}) {
    ${(p) => p.hasStudyTitle && 'padding-top: 10px;'}
  }

  @media (max-width: ${MobileHeaderEligibilityMaxWidth}) {
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
  }

  @media (min-width: ${desktopMinWidth}) {
    & > * {
      padding-left: 0 !important;
      margin-right: auto;
    }

    & > #t-AchievementLogo {
      padding-bottom: 5px !important;
    }
  }

  ${(p) =>
    p.centered &&
    css`
      @media (max-width: ${mobileMaxWidth}) {
        display: flex;
        flex: 1 auto;
        justify-content: center;
        margin-right: 30px;
      }
    `};
`;

const StudyTitle = styled.div`
  color: #2a3f53;
  font-family: Heebo;
  font-size: 1rem;
  line-height: 24px;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(p) => (p.enrollmentIsClosed ? '70vw' : '50vw')};
  align-self: center;
`;

export class Header extends Component {
  // TODO: Move navigation state into redux
  state = { showMobileDropdown: false };
  myRef = React.createRef();

  componentDidMount() {
    if (this.myRef.current) {
      Stickyfill.add(this.myRef.current);
    }
  }

  toggleMobileNavOff = () => this.setState({ showMobileDropdown: false });
  toggleMobileNav = () =>
    this.setState({ showMobileDropdown: !this.state.showMobileDropdown });

  render() {
    const {
      enrollmentLogOut,
      status,
      study_status,
      layout,
      participant_layout,
      portalEmail,
      hasAcmCookie,
      hero_title,
      hero_content,
      meta: {
        study_name,
        is_program = false,
        brand,
        authentication = {},
        slug,
      },
      setIsLoading,
      t,
      enrollment_identifier,
      participant_auth_token,
      destroyAuthToken,
    } = this.props;
    let { hero_button } = this.props;

    const buttonText = t('components.header.eligibilityButton');
    hero_button = hero_button || buttonText;
    const startButtonText = t('components.header.startButton');

    const enrolled = isEnrolled(status, participant_layout);

    const loginable = canLogin(
      status,
      participant_layout,
      portalEmail,
      hasAcmCookie,
      is_program,
    );

    const logoutable = canLogout(
      loginable,
      enrolled,
      portalEmail,
      hasAcmCookie,
      participant_layout,
      is_program,
    );

    const isLanding = this.props.location.pathname === `/`;
    const isEducation =
      layout === `education` && participant_layout !== 'dashboard' && isLanding;
    const enrollmentIsClosed = Object.values(
      STUDY_STATUSES_ENROLLMENT_CLOSED,
    ).includes(study_status);

    const authProvider = _.has(authentication, 'provider')
      ? _.includes(
          EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
          authentication.provider.toLowerCase(),
        )
      : false;

    // IF we have a language inside the secondary_locales array, then we must give the user the
    //  ability to change languages.
    const shouldDisplayLanguagePicker =
      this.props?.meta?.i18n_enabled &&
      this.props?.meta?.secondary_locales?.length > 0;
    const isOpenEnrollmentLandingPage = isEducation && !enrollmentIsClosed;
    const shouldDisplayEligibility =
      isOpenEnrollmentLandingPage && !authProvider;

    const LeftLinks = () =>
      enrolled && (
        <React.Fragment>
          <DashboardLink onClick={this.toggleMobileNavOff} />
          <SettingsLink onClick={this.toggleMobileNavOff} />
        </React.Fragment>
      );

    // Used to determine if a third-party url is needed to complete
    // user logout process.
    const logout = async (loginType) => {
      await destroyAuthToken(
        enrollment_identifier,
        participant_auth_token,
        participant_auth_token,
      );
      if (
        loginType &&
        authentication?.details?.logout &&
        simpleUserLogout(slug)
      ) {
        window.location.href = authentication.details.logout;
      } else {
        enrollmentLogOut();
      }
    };

    const RightLinks = ({ setLoginType }) => {
      const home = useTranslated('components.header.links.home');
      return (
        <>
          <TextSizeSelect />
          {isEducation && (
            <StyledNavLink
              to="/"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
                this.toggleMobileNavOff();
              }}
              isActive={(match, location) => match && location.hash === ``}
            >
              {home}
            </StyledNavLink>
          )}
          {isEducation && <FaqLink onClick={this.toggleMobileNavOff} />}
          <ContactModal onClose={this.toggleMobileNavOff}>
            {({ showModal }) => (
              <ContactUsLink
                onClick={showModal}
                padding={this.state.showMobileDropdown ? '0' : '15px'}
              />
            )}
          </ContactModal>
          {loginable && !setLoginType && (
            <LogInLink onClick={this.toggleMobileNavOff} />
          )}
          {loginable && setLoginType && (
            <CognitoLogin setIsLoading={setIsLoading} />
          )}
          {logoutable && <LogOutLink onClick={() => logout(setLoginType)} />}
        </>
      );
    };

    return (
      <StickyHeader sticky={isEducation} ref={this.myRef}>
        <Spacer
          pl={LandingSidePadding}
          pr={LandingSidePadding}
          style={{ height: '100%' }}
        >
          <HeaderContent>
            <NavContainer noCheckEligibility={!isOpenEnrollmentLandingPage}>
              <Hamburger
                onClick={this.toggleMobileNav}
                active={this.state.showMobileDropdown}
              />
              <TitleSection
                centered={!isOpenEnrollmentLandingPage}
                hasStudyTitle={study_name}
                isEducation={isEducation}
              >
                <LogoLink
                  to={logoLink(participant_layout)}
                  onClick={this.toggleMobileNavOff}
                  id="t-AchievementLogo"
                />
                <StudyTitle
                  id="t-studyTitle"
                  enrollmentIsClosed={enrollmentIsClosed}
                >
                  {/* TODO: discuss if this will be controllable for all future studies */}
                  {brand?.brand_name !== 'PPMI' && study_name}
                </StudyTitle>
              </TitleSection>

              <MobileHeaderEligibility>
                {shouldDisplayLanguagePicker && (
                  <LanguagePicker
                    locale={this.props?.meta?.locale}
                    primaryLocale={this.props?.meta?.primary_locale}
                    secondaryLocales={this.props.meta.secondary_locales}
                    participantLocale={this.props.participantLocale}
                  />
                )}
                {shouldDisplayEligibility && (
                  <CheckEligibilityModal
                    title={hero_title}
                    content={hero_content}
                    buttonLabel={hero_button}
                    render={({ onClick }) => (
                      <HeaderButton onClick={onClick}>
                        {startButtonText}
                      </HeaderButton>
                    )}
                  />
                )}
              </MobileHeaderEligibility>

              <DesktopLinks>
                <Flex>
                  <LeftLinks />
                </Flex>
                <Flex items="center">
                  <RightLinks setLoginType={authProvider} />
                  <>
                    {shouldDisplayLanguagePicker && (
                      <LanguagePicker
                        locale={this.props?.meta?.locale}
                        primaryLocale={this.props?.meta?.primary_locale}
                        secondaryLocales={this.props.meta.secondary_locales}
                        participantLocale={this.props.participantLocale}
                      />
                    )}
                    {shouldDisplayEligibility && (
                      <CheckEligibilityModal
                        title={hero_title}
                        content={hero_content}
                        buttonLabel={hero_button}
                        render={({ onClick }) => (
                          <DesktopHeaderButton onClick={onClick}>
                            {hero_button}
                          </DesktopHeaderButton>
                        )}
                      />
                    )}
                  </>
                </Flex>
              </DesktopLinks>
            </NavContainer>
          </HeaderContent>
        </Spacer>
        <MobileLinks visible={this.state.showMobileDropdown}>
          <LeftLinks />
          <RightLinks />
        </MobileLinks>
      </StickyHeader>
    );
  }
}

export default withRouter(
  connect(
    ({
      participant: {
        portalEmail,
        hasAcmCookie,
        layout: participant_layout,
        enrollment_identifier,
        participant_auth_token,
        locale,
      },
      landing: { layout, nodes },
    }) => {
      const hero_props = {};
      if (!_.isEmpty(nodes)) {
        Object.assign(
          hero_props,
          _.pick(nodes[0].content, [
            'hero_title',
            'hero_content',
            'hero_button',
          ]),
        );
      }
      return {
        portalEmail,
        hasAcmCookie,
        layout,
        participant_layout,
        enrollment_identifier,
        participant_auth_token,
        participantLocale: locale,
        ...hero_props,
      };
    },
    (dispatch) => ({
      destroyAuthToken: (
        enrollment_identifier,
        participant_auth_token,
        device_auth_token,
      ) =>
        dispatch(
          actions.destroyAuthToken(
            enrollment_identifier,
            participant_auth_token,
            device_auth_token,
          ),
        ),
    }),
  )(withTranslation()(Header)),
);
