// @ts-nocheck
import React, { useState } from 'react';
import { useLanguageContext } from '../contexts';
import { NavLinkDiv } from './NavLink.styles';
import { StyledLocales } from './LocalesModalContent.styles';
import { connect } from 'react-redux';
import { actions } from '../redux';
import { nativeNames } from '../i18n/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingIndicatorSmall } from './loadingIndicator';
import { updateEnrollment as updateEnrollmentApiCall } from '../services/updateEnrollment';

/**
 * Helper component that takes in a locale string and normalizes it and returns the translated language name in the language you are in.
 * @param {Object} props
 * @param {string} props.locale eg: 'es' , 'en_US'
 */
const LocaleFullNameInLanguage = ({ locale }) => (
  <span>{nativeNames[locale.replace('-', '')]}</span>
);

/**
 * @description Displays the available languages we can switch the study to, it also changes the language on the whole app.
 * @param {Object} props
 * @param {string} props.participantAuthToken Passed in via Redux
 * @param {string} props.enrollmentIdentifier Passed in via Redux
 * @param {string} props.selectedLocale The currently selected locale
 * @param {string[]} props.localesForModal strings of 'es' , 'en_US'
 * @param {(locale: string) => void} props.setLocaleText passed down from `LanguagePicker` to change the value of the locale set currently.
 * @param {() => Promise<any>} props.getLanding Redux action that re-fetches the
 *   /landing endpoint to refresh study data.
 * @param {() => void} props.closeModal close modal when selecting a new current locale.
 * @param {(...args: Parameters<updateEnrollmentApiCall>) => Promise<any>} [props.updateEnrollment]
 *   API call to update the enrollment with the new locale. Used for mocking.
 */
export const LocalesModalContent = ({
  participantAuthToken,
  enrollmentIdentifier,
  selectedLocale,
  localesForModal,
  setLocaleText,
  getLanding,
  closeModal,
  updateEnrollment = updateEnrollmentApiCall,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { changeLanguage } = useLanguageContext();

  const setNewLocaleBasedOnModalSelection = async (locale) => {
    if (selectedLocale === locale) {
      return;
    }

    setIsLoading(true);
    setLocaleText(locale);
    changeLanguage(locale); // Changing the language on the study itself.
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('locale_override', locale);
    history.push({ search: searchParams.toString() });
    if (enrollmentIdentifier) {
      await updateEnrollment({
        enrollmentIdentifier,
        participantAuthToken,
        data: { locale },
      });
    }
    await getLanding(); // this makes it so the data is re-fetched and the Education tile re-renders
    closeModal();
  };

  return (
    <StyledLocales>
      {isLoading ? (
        <LoadingIndicatorSmall />
      ) : (
        localesForModal.map((locale, idx) => (
          <NavLinkDiv
            key={idx}
            className={selectedLocale === locale ? 'active' : null}
            onClick={() => setNewLocaleBasedOnModalSelection(locale)}
          >
            <LocaleFullNameInLanguage locale={locale} />
          </NavLinkDiv>
        ))
      )}
    </StyledLocales>
  );
};

export default connect(
  (/** @type {import('../redux').RootState} */ state) => ({
    participantAuthToken: state.participant.participant_auth_token,
    enrollmentIdentifier: state.participant.enrollment_identifier,
  }),
  (dispatch) => ({
    getLanding: () => dispatch(actions.getLanding()),
  }),
)(LocalesModalContent);
