import styled from 'styled-components';

/**
 * @description Style component for the language picker container element.
 */
export const StyledLocales = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
