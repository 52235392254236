import { typedUseSelector } from "../redux/utils";
/**
 * useBrand Hook
 * returns the same information as the withBrand HOC
 */
export default function useBrand () {
  const meta = typedUseSelector((state) => state.meta);

  return {
    brandName: meta?.brand?.brand_name || 'Evidation',
    companyName:
      meta?.brand?.branding_json?.company_name ||
      meta?.brand?.brand_name ||
      'Evidation',
    companyUrl: meta?.brand?.branding_json?.company_url || 'myachievement.com',
    emailDomain: meta?.brand?.branding_json?.email_domain,
    branding: {
      ...meta?.brand
    }
  }
}
