import logger, {
  createLogger,
  createDefaultLogger,
  LEVELS,
  LoggerMiddleware,
} from '@evidation/logger';
import { AnyAction } from 'redux';
import { RootState } from '../redux';
import { getReduxState } from '../redux/utils';
import axios from 'axios';
import { DynamicObject } from 'src/types/utils';

const SENSITIVE_FIELD_NAMES = [
  'password',
  'first_name',
  'last_name',
  'email',
  'postal_code',
];

const clone = (obj: { [key: string]: any }) => ({ ...obj });

export class NetworkError extends Error {
  constructor(message?: string) {
    let errorMessage = 'HTTP request failed';
    if (message) {
      errorMessage += `: ${message}`;
    }
    super(errorMessage);
    this.name = 'NetworkError';
  }
}

/**
 * Returns the given object with sensitive data replaced with a placeholder
 * value.
 *
 * @param data - The data to scrub
 * @param sensitiveFieldNames - An array of key names for sensitive fields
 * @param placeholderValue - The value with which to replace sensitive data
 * @returns If the given object had any sensitive data, returns a copy of the
 *   object with the relevant fields redacted; otherwise, returns the same
 *   object.
 */
export function scrubSensitiveData(
  data: { [key: string]: any },
  sensitiveFieldNames: Array<string> = SENSITIVE_FIELD_NAMES,
  placeholderValue: string = '[FILTERED]',
) {
  // We start with the original object, and if no sensitive fields are found, we
  // return the object as-is to skip the performance penalty of making a copy on
  // a potentially large object.
  let scrubbedData = data;

  // Ensures we only clone the object once.
  const cloneIfNecessary = () => {
    if (scrubbedData === data) {
      scrubbedData = clone(data);
    }
  };

  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'object' && value !== null) {
      const scrubbedValue = scrubSensitiveData(
        value,
        sensitiveFieldNames,
        placeholderValue,
      );
      if (scrubbedValue !== value) {
        cloneIfNecessary();
        scrubbedData[key] = scrubbedValue;
      }
    } else if (sensitiveFieldNames.includes(key)) {
      cloneIfNecessary();
      scrubbedData[key] = placeholderValue;
    }
  }
  return scrubbedData;
}

export const createBillboardMiddleware =
  ({
    getReduxState: _getReduxState = getReduxState,
  }: {
    getReduxState?: typeof getReduxState;
  } = {}): LoggerMiddleware =>
  ({ message, logLevel, attributes, next }) => {
    /*
     * If we have a response we don't want to report an error here.
     * This will be caught by Triage and will help clean up our logs
     * to show important errors only.
     */
    if (
      logLevel === LEVELS.error &&
      axios.isAxiosError(message) &&
      message.response
    ) {
      return;
    }
    const enrollmentIdentifier =
      _getReduxState()?.participant?.enrollment_identifier;
    attributes = { ...attributes };
    if (enrollmentIdentifier) {
      attributes.enrollment_identifier = enrollmentIdentifier;
    }
    if (axios.isAxiosError(message)) {
      attributes.original_request_data = scrubSensitiveData(
        JSON.parse(message.config.data || '{}'),
      );
      attributes.original_request_url = message.config.url;
      message = new NetworkError(message.message);
    }
    next(message, logLevel, attributes);
  };

export const createBillboardLogger = ({
  getReduxState: _getReduxState = getReduxState,
}: {
  getReduxState?: typeof getReduxState;
} = {}) => createDefaultLogger({ middlewares: [createBillboardMiddleware()] });

const billboardLogger = createBillboardLogger();

/**
 * @deprecated please use logError
 * This legacy function is used inside redux actions and allows some reporting via
 * redux-api-middleware. This function should not be used in new code.
 */
export const logLegacyReduxApiError = ({
  action,
  state,
  response,
  request,
  // For mocking:
  logger: _logger = logger,
  getReduxState: _getReduxState = getReduxState,
}: {
  action?: AnyAction;
  state?: RootState;
  response?: Response;
  request?: { [key: string]: any };
  logger?: typeof logger;
  getReduxState?: typeof getReduxState;
}) => {
  const { meta } = state || {};
  const apiAction = action?.['@@redux-api-middleware/RSAA'];

  // If we got a response back, don't log it because Triage will.
  if (response) {
    return;
  }

  const tags: DynamicObject = {};
  if (apiAction) {
    Object.assign(tags, {
      endpoint: apiAction?.endpoint,
      redux_action: apiAction?.types[0],
    });
  }
  const enrollmentIdentifier =
    _getReduxState()?.participant?.enrollment_identifier || undefined;
  if (enrollmentIdentifier) {
    tags.enrollment_identifier = enrollmentIdentifier;
  }
  if (meta?.slug) {
    tags.slug = meta.slug;
  }
  if (request) {
    tags.request = scrubSensitiveData(request);
  }

  billboardLogger.error(new NetworkError(), tags);
};

export const logError = (error: unknown) => {
  billboardLogger.error(error);
};

export { createLogger, createDefaultLogger };

export default billboardLogger;
