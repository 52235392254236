import { STUDY_ID, TRIAGE_ENDPOINT } from '../../constants';

import { RSAA } from 'redux-api-middleware';
import { REDUX_API_FAILURE } from '../errors/actions';

const baseURL = `${TRIAGE_ENDPOINT}/api/v3/meta/study`;

const authHeader = (token: string) => ({
  'Content-Type': 'application/json',
  'participant-auth-token': token,
});

export const getMeta = (auth_token: string, enrollment_identifier: string) => ({
  [RSAA]: {
    endpoint:
      baseURL +
      `?study_slug=${STUDY_ID}&enrollment_identifier=${enrollment_identifier}`,
    method: 'GET',
    headers: authHeader(auth_token),
    types: ['META/REQUEST', 'META/RECEIVE', REDUX_API_FAILURE],
  },
});
