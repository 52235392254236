import React from 'react';

export default ({ style }: { style?: React.CSSProperties } = {}) => (
  <svg
    width="151px"
    height="20px"
    viewBox="0 0 151 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={style}
  >
    <title>ACM_logo</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Navg"
        transform="translate(-132.000000, -16.000000)"
        fill="#2A3F53"
        fillRule="nonzero"
      >
        <g id="Group">
          <g transform="translate(132.000000, 16.000000)">
            <g id="ACM_logo">
              <g id="AM_Logo">
                <path
                  d="M16.529669,18.9177111 C16.5663257,19.0054752 16.5568563,19.1060598 16.5044855,19.1852109 C16.4521146,19.2643621 16.3639036,19.3114077 16.2698925,19.3103266 L13.5540476,19.3103266 C13.3025327,19.3093707 13.0766163,19.1541704 12.9825394,18.9177111 L11.2727379,14.6372446 L5.23175418,14.6372446 L3.54556874,18.9177111 C3.45149176,19.1541704 3.22557539,19.3093707 2.97406051,19.3103266 L0.281831653,19.3103266 C0.187820586,19.3114077 0.0996095179,19.2643621 0.0472386724,19.1852109 C-0.00513217311,19.1060598 -0.0146016052,19.0054752 0.022055185,18.9177111 L7.64059052,1.08243391 C7.72909511,0.841475919 7.9585269,0.683860557 8.21209875,0.689818413 L8.35851822,0.689818413 C8.6100331,0.690774269 8.83594947,0.845974602 8.93002645,1.08243391 L16.529669,18.9177111 Z M6.31809214,11.932028 L10.2478016,11.932028 L8.30656292,6.99081835 L6.31809214,11.932028 Z"
                  id="A"
                />
                <path
                  d="M29.5120278,17.1648477 C29.5977922,17.2416335 29.649246,17.3502612 29.6546919,17.4660364 C29.6601378,17.5818116 29.6191131,17.6948974 29.5409441,17.7795859 C28.290621,19.2131075 26.4851433,20.0236648 24.5962503,19.9994736 C19.5214331,19.9994736 17.9310345,15.9841602 17.9310345,13.1690499 C17.9310345,10.1978156 19.2804636,6.20689662 24.5432371,6.20689662 C26.3749898,6.20662556 28.1230237,6.9834841 29.3626267,8.34872233 C29.520635,8.52241839 29.520635,8.7897644 29.3626267,8.96346046 L28.1481405,10.3539396 C28.0774185,10.4406694 27.9739029,10.4932596 27.8629475,10.4988295 C27.7519921,10.5043993 27.643845,10.4624343 27.5649943,10.3832128 C26.7426066,9.60809221 25.6752818,9.15341843 24.5528758,9.10006893 C22.3070403,9.10006893 21.0636377,11.1052862 21.0636377,13.1690499 C21.0636377,15.6865489 22.7552435,16.998966 24.5817922,16.998966 C25.6329589,16.9603379 26.6360499,16.5434236 27.4107739,15.8231574 C27.5927089,15.6613515 27.8649983,15.6613515 28.0469333,15.8231574 L29.5120278,17.1648477 Z"
                  id="c"
                />
                <path
                  d="M32.8390971,19.3103166 C32.7259108,19.3116284 32.6169782,19.2671383 32.5369381,19.1869094 C32.456898,19.1066805 32.4125126,18.997491 32.4138212,18.8840378 L32.4138212,1.11596218 C32.4125126,1.00250897 32.456898,0.893319481 32.5369381,0.813090586 C32.6169782,0.73286169 32.7259108,0.688371647 32.8390971,0.689683377 L35.120122,0.689683377 C35.2333083,0.688371647 35.3422409,0.73286169 35.422281,0.813090586 C35.5023212,0.893319481 35.5467065,1.00250897 35.5453979,1.11596218 L35.5453979,6.80290896 C36.3961084,6.14334534 37.4420072,5.78707155 38.5174961,5.7904968 C43.5821447,5.7904968 44.1379029,9.2733884 44.1379029,12.4365709 L44.1379029,18.8695056 C44.1392115,18.9829588 44.0948262,19.0921483 44.014786,19.1723772 C43.9347459,19.2526061 43.8258133,19.2970961 43.7126271,19.2957844 L41.4412675,19.2957844 C41.3280812,19.2970961 41.2191486,19.2526061 41.1391085,19.1723772 C41.0590683,19.0921483 41.014683,18.9829588 41.0159916,18.8695056 L41.0159916,12.4559472 C41.0159916,10.566757 40.7501942,8.49349195 38.0438934,8.5467768 C37.0593386,8.5826344 36.143323,9.06129588 35.5502306,9.8498336 L35.5502306,18.8840378 C35.5515392,18.997491 35.5071538,19.1066805 35.4271137,19.1869094 C35.3470736,19.2671383 35.238141,19.3116284 35.1249547,19.3103166 L32.8390971,19.3103166 Z"
                  id="h"
                />
                <path
                  d="M48.6206613,5.96075729e-05 C49.0790575,-0.00385973896 49.5197632,0.18564473 49.843909,0.526058915 C50.1680548,0.8664731 50.3485029,1.32929719 50.3447708,1.81069959 C50.3447708,2.81068844 49.5728607,3.62133957 48.6206613,3.62133957 C47.6684619,3.62133957 46.8965517,2.81068844 46.8965517,1.81069959 C46.8965517,0.81071074 47.6684619,5.96075729e-05 48.6206613,5.96075729e-05 Z M50.1182792,18.8831414 C50.1195309,18.9968332 50.077078,19.1062523 50.0005226,19.1866499 C49.9239672,19.2670475 49.8197772,19.3116311 49.7115188,19.3103166 L47.5066924,19.3103166 C47.3984339,19.3116311 47.294244,19.2670475 47.2176886,19.1866499 C47.1411332,19.1062523 47.0986803,18.9968332 47.0999319,18.8831414 L47.0999319,6.41739488 C47.0986803,6.30370311 47.1411332,6.19428404 47.2176886,6.11388645 C47.294244,6.03348886 47.3984339,5.98890527 47.5066924,5.99021976 L49.7115188,5.99021976 C49.8197772,5.98890527 49.9239672,6.03348886 50.0005226,6.11388645 C50.077078,6.19428404 50.1195309,6.30370311 50.1182792,6.41739488 L50.1182792,18.8831414 Z"
                  id="i"
                />
                <path
                  d="M63.3895166,17.7661007 C63.5243255,17.9608131 63.491407,18.2311609 63.3142114,18.384555 C62.0605732,19.4494579 60.4879547,20.0210414 58.8712085,19.9994077 C53.9151892,19.9994077 52.4137931,15.9892719 52.4137931,13.0786103 C52.4137931,10.2513909 53.9104827,6.20689655 58.8712085,6.20689655 C62.255233,6.20689655 64.1378613,8.46965372 64.1378613,11.9447775 C64.1398956,12.5037695 64.0973902,13.0619712 64.0107839,13.6136223 C63.9820628,13.8614777 63.7795151,14.0473618 63.5401268,14.0455586 L55.5389562,14.0455586 C55.7460453,15.3659093 56.4802704,17.1967302 58.8712085,17.1967302 C59.9220947,17.2016668 60.9524657,16.8934365 61.8410547,16.3083157 C62.0298717,16.1895922 62.2738897,16.2358613 62.4105498,16.4162998 L63.3895166,17.7661007 Z M55.6660336,11.4932078 L61.2197874,11.4932078 C61.2715596,10.3642834 60.7020646,9.03902428 58.8665019,8.93594858 C56.7767844,8.96049041 56.0002002,10.1973989 55.6660336,11.4882994 L55.6660336,11.4932078 Z"
                  id="e"
                />
                <path
                  d="M77.6684554,6.20689655 C77.7606029,6.21269062 77.8439234,6.2621386 77.8912898,6.3391415 C77.9386563,6.41614441 77.9440097,6.51085209 77.9056071,6.59243021 L72.1704084,19.0000318 C72.0863117,19.1872346 71.8966758,19.3088204 71.6864254,19.3103393 C71.4754098,19.3112898 71.2845026,19.1888889 71.2024424,19.0000318 L65.5398411,6.61593836 C65.5022792,6.52975735 65.5119825,6.43098704 65.5656464,6.35326359 C65.6193104,6.27554013 65.7096995,6.22934304 65.8060318,6.2304047 L68.4098604,6.2304047 C68.6675855,6.23134332 68.89908,6.38374421 68.9954798,6.61593836 L71.7590228,13.1700106 L74.488687,6.61593836 C74.5850869,6.38374421 74.8165814,6.23134332 75.0743065,6.2304047 L77.6684554,6.20689655 Z"
                  id="v"
                />
                <path
                  d="M89.5964131,17.7661007 C89.7312221,17.9608131 89.6983036,18.2311609 89.521108,18.384555 C88.2674698,19.4494579 86.6948513,20.0210414 85.078105,19.9994077 C80.1220858,19.9994077 78.6206897,15.9892719 78.6206897,13.0786103 C78.6206897,10.2513909 80.1173792,6.20689655 85.078105,6.20689655 C88.4621295,6.20689655 90.3447579,8.46965372 90.3447579,11.9447775 C90.3467921,12.5037695 90.3042868,13.0619712 90.2176805,13.6136223 C90.1889594,13.8614777 89.9864117,14.0473618 89.7470234,14.0455586 L81.7458528,14.0455586 C81.9529419,15.3659093 82.687167,17.1967302 85.078105,17.1967302 C86.1289912,17.2016668 87.1593623,16.8934365 88.0479513,16.3083157 C88.2367683,16.1895922 88.4807862,16.2358613 88.6174464,16.4162998 L89.5964131,17.7661007 Z M81.8776368,11.4882994 L87.4313905,11.4882994 C87.4831628,10.359375 86.9136677,9.03411591 85.078105,8.93104021 C82.9883875,8.96049041 82.2118033,10.1973989 81.8776368,11.4882994 Z"
                  id="e"
                />
                <path
                  d="M96.9379085,18.8785429 C96.9392226,18.9884625 96.8946502,19.0942511 96.8142727,19.1719812 C96.7338952,19.2497112 96.6245034,19.2928156 96.51084,19.2915447 L94.2202002,19.2915447 C94.1065368,19.2928156 93.997145,19.2497112 93.9167675,19.1719812 C93.83639,19.0942511 93.7918175,18.9884625 93.7931317,18.8785429 L93.7931317,6.82639977 C93.7918175,6.71648021 93.83639,6.61069157 93.9167675,6.53296151 C93.997145,6.45523145 94.1065368,6.41212711 94.2202002,6.41339798 L95.4286098,6.41339798 C95.6817626,6.40950237 95.9080213,6.56552068 95.9867106,6.79824056 L96.2245101,7.41774324 C97.2768499,6.63219086 98.5688849,6.2063449 99.8982694,6.20689709 C101.946257,6.20689709 103.174079,6.77477455 103.945714,7.64771015 L104.236897,7.41774324 C105.289236,6.63219086 106.581271,6.2063449 107.910656,6.20689709 C112.919004,6.20689709 113.10342,9.63293466 113.10342,12.6459704 L113.10342,18.8785429 C113.104734,18.9884625 113.060162,19.0942511 112.979784,19.1719812 C112.899407,19.2497112 112.790015,19.2928156 112.676352,19.2915447 L110.332328,19.2915447 C110.218665,19.2928156 110.109273,19.2497112 110.028895,19.1719812 C109.948518,19.0942511 109.903946,18.9884625 109.90526,18.8785429 L109.90526,12.6506636 C109.90526,11.0549749 110.118794,8.86324951 107.77477,8.86324951 C106.660614,8.86087516 105.616073,9.38698782 104.979413,10.2712102 C105.06874,11.0659638 105.104404,11.865503 105.08618,12.6647433 L105.08618,18.8973157 C105.087495,19.0072353 105.042922,19.1130239 104.962545,19.190754 C104.882167,19.268484 104.772775,19.3115884 104.659112,19.3103175 L102.315089,19.3103175 C102.201425,19.3115884 102.092033,19.268484 102.011656,19.190754 C101.931278,19.1130239 101.886706,19.0072353 101.88802,18.8973157 L101.88802,12.6506636 C101.88802,11.0549749 102.101554,8.86324951 99.7575309,8.86324951 C98.6331029,8.85157391 97.575558,9.37874493 96.9330554,10.2712102 L96.9379085,18.8785429 Z"
                  id="m"
                />
                <path
                  d="M126.148137,17.7661007 C126.282946,17.9608131 126.250028,18.2311609 126.072832,18.384555 C124.819194,19.4494579 123.246575,20.0210414 121.629829,19.9994077 C116.67381,19.9994077 115.172414,15.9892719 115.172414,13.0786103 C115.172414,10.2513909 116.669103,6.20689655 121.629829,6.20689655 C125.013854,6.20689655 126.896482,8.46965372 126.896482,11.9447775 C126.898516,12.5037695 126.856011,13.0619712 126.769405,13.6136223 C126.740684,13.8614777 126.538136,14.0473618 126.298748,14.0455586 L118.32111,14.0455586 C118.528199,15.3659093 119.262424,17.1967302 121.653362,17.1967302 C122.704248,17.2016668 123.734619,16.8934365 124.623208,16.3083157 C124.812025,16.1895922 125.056043,16.2358613 125.192703,16.4162998 L126.148137,17.7661007 Z M118.424654,11.4932078 L123.968995,11.4932078 C124.020767,10.3642834 123.451272,9.03902428 121.615709,8.93594858 C119.540112,8.96049041 118.763527,10.1973989 118.429361,11.4882994 L118.424654,11.4932078 Z"
                  id="e"
                />
                <path
                  d="M132.788069,18.8967231 C132.789378,19.0068004 132.744974,19.1127408 132.664901,19.1905824 C132.584828,19.268424 132.47585,19.3115902 132.362617,19.3103175 L130.080652,19.3103175 C129.967419,19.3115902 129.858441,19.268424 129.778368,19.1905824 C129.698295,19.1127408 129.653891,19.0068004 129.655201,18.8967231 L129.655201,6.82728858 C129.653891,6.71721132 129.698295,6.6112709 129.778368,6.53342932 C129.858441,6.45558774 129.967419,6.41242156 130.080652,6.41369425 L131.274816,6.41369425 C131.52701,6.40979305 131.752412,6.5660352 131.830803,6.79908897 L132.067702,7.41948047 C133.116057,6.63280103 134.403199,6.20634411 135.72755,6.20689709 C140.716932,6.20689709 141.379282,9.63785006 141.379282,12.6552087 L141.379282,18.8967231 C141.380591,19.0068004 141.336188,19.1127408 141.256115,19.1905824 C141.176041,19.268424 141.067064,19.3115902 140.953831,19.3103175 L138.618684,19.3103175 C138.505451,19.3115902 138.396474,19.268424 138.3164,19.1905824 C138.236327,19.1127408 138.191924,19.0068004 138.193233,18.8967231 L138.193233,12.6599086 C138.193233,11.0619305 137.927326,8.86706062 135.592179,8.86706062 C134.472009,8.85536827 133.418469,9.38329564 132.778399,10.2770413 L132.788069,18.8967231 Z"
                  id="n"
                />
                <path
                  d="M143.169971,8.71495972 C143.060498,8.7162527 142.955139,8.6723985 142.877725,8.59331622 C142.800311,8.51423393 142.757382,8.40660496 142.758648,8.29477319 L142.758648,6.46123201 C142.757382,6.34940025 142.800311,6.24177127 142.877725,6.16268899 C142.955139,6.08360671 143.060498,6.03975251 143.169971,6.04104549 L144.142188,6.04104549 L144.34785,3.86849018 C144.376373,3.6273771 144.577524,3.44654944 144.815262,3.44830366 L146.787741,3.44830366 C146.897214,3.44701068 147.002573,3.49086488 147.079987,3.56994716 C147.157401,3.64902945 147.20033,3.75665842 147.199064,3.86849018 L147.199064,6.04104549 L149.302419,6.04104549 C149.411892,6.03975251 149.51725,6.08360671 149.594665,6.16268899 C149.672079,6.24177127 149.715008,6.34940025 149.713742,6.46123201 L149.713742,8.29477319 C149.715008,8.40660496 149.672079,8.51423393 149.594665,8.59331622 C149.51725,8.6723985 149.411892,8.7162527 149.302419,8.71495972 L147.199064,8.71495972 L147.199064,15.4761428 C147.199064,16.1302968 147.27385,16.7319275 148.133889,16.6794042 C148.362921,16.65553 148.825659,16.5504834 149.536125,16.3929134 C149.639105,16.3704174 149.746614,16.3911238 149.834505,16.4503818 C149.922396,16.5096397 149.9833,16.6024815 150.003538,16.7080533 L150.3354,18.307627 C150.384586,18.546066 150.236491,18.7804529 150.003538,18.8328601 C149.036911,19.1222436 148.037714,19.2827356 147.030796,19.3103448 C144.389917,19.3103448 144.13284,16.7940005 144.13284,15.905879 L144.13284,8.7436088 L143.169971,8.71495972 Z"
                  id="t"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
