import { triagev3, config } from './';

export const updateEnrollment = ({
  enrollmentIdentifier,
  participantAuthToken,
  data,
}: {
  enrollmentIdentifier: string;
  participantAuthToken: string;
  data: { [key: string]: any };
}) =>
  triagev3.request({
    url: `/enrollments/${enrollmentIdentifier}`,
    method: 'PATCH',
    ...config({ auth_token: participantAuthToken }),
    data,
  });
