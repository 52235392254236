import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer,
} from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import { participant } from './participant/reducers';
import { landing } from './landing/reducers';
import { meta } from './meta/reducers';
import { errors } from './errors/reducers';
import { reducer as toastr } from 'react-redux-toastr';

import * as ParticipantActions from './participant/actions';
import * as MetaActions from './meta/actions';
import * as LandingActions from './landing/actions';
import * as ErrorActions from './errors/actions';

export const actions = {
  ...ParticipantActions,
  ...MetaActions,
  ...LandingActions,
  ...ErrorActions,
};

export const reducer = combineReducers({
  participant,
  meta,
  landing,
  errors,
  form,
  toastr,
});

export const middleware: StoreEnhancer<{ dispatch: unknown }, {}> =
  window.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? compose(
        applyMiddleware(apiMiddleware, thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__(),
      )
    : applyMiddleware(apiMiddleware, thunk);

export const store = createStore(reducer, undefined, middleware);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
