import React from 'react';
import styled from 'styled-components';

import { Spacer } from '@evidation/ui';

import { StyledButton } from '../../components/BillboardNavigation';
import BasicStyledModal from '../../components/styledModal';
import { useTranslationWithPrefix } from '../../hooks';

const leftPadSeconds = (seconds: number) => ('00' + seconds).slice(-2);

export const prettyPrintSeconds = (seconds: number, t: any) => {
  const minutes = Math.floor(seconds / 60);
  if (minutes < 1) {
    return `${seconds} ${t('seconds', { count: seconds })}`;
  }
  const remainder = seconds % 60;
  return `${minutes}:${leftPadSeconds(remainder)}`;
};

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Countdown = styled.span`
  font-weight: bold;
`;

const AuthExpirationModal = ({
  secondsBeforeExpiration,
  isLoading,
  closeModal,
  onLogOut,
  onStayLoggedIn,
}: {
  secondsBeforeExpiration: number;
  isLoading: boolean;
  closeModal: () => any;
  onLogOut: () => any;
  onStayLoggedIn: () => any;
}) => {
  const { t } = useTranslationWithPrefix('components.authExpirationModal');
  return (
    <BasicStyledModal
      isOpen={true}
      header={
        <>
          <img
            src="/images/logout-icon.svg"
            alt="Logout Icon"
            style={{ display: 'block' }}
          />
          {t('sessionAboutToExpire')}
        </>
      }
      closeModal={closeModal}
    >
      {t('youWillBeLoggedOutIn') + ' '}
      <Countdown>
        {prettyPrintSeconds(secondsBeforeExpiration, t)}
      </Countdown>. {t('pleaseChoose')}
      <Spacer size={20} />
      <Buttons>
        {/*
         * TODO: The `buttonStyle` here doesn't actually do anything, but we
         * will need to change this button to an "outlined" version as per the
         * design in PD-107 once the relevant design system work is done.
         */}
        <StyledButton
          onClick={() => onLogOut()}
          buttonStyle="outline"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t('logOut')}
        </StyledButton>
        <StyledButton
          onClick={() => onStayLoggedIn()}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t('stayLoggedIn')}
        </StyledButton>
      </Buttons>
    </BasicStyledModal>
  );
};
export default AuthExpirationModal;
