import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import { primaryFontFamily, secondaryFontFamily } from '../utils/brandHelpers';
import { useTranslated } from '../hooks';

// Values used for populating the cookie banner.
const cookieBannerLocation = 'bottom';
const cookieExpirationInDays = 365;
const noticeOfCollectionUrl = 'https://www.myachievement.com/privacy';
const date = new Date();
const year = date.getFullYear();
const cookieName = `eh-${year}`;

// Styles for the cookie banner.
const StyledCookieBanner = styled.div`
  div {
    background: ${({ theme }) => theme?.branding_json?.color_1} !important;
    color: ${({ theme }) => theme?.branding_json?.color_3} !important;
  }

  button {
    background: ${({ theme }) => theme?.branding_json?.color_4} !important;
    border: 0 !important;
    border-radius: 5px !important;
    color: ${({ theme }) => theme?.branding_json?.color_6} !important;
    font-family: ${({ theme }) =>
      theme?.brand_name === 'PPMI'
        ? primaryFontFamily(theme)
        : secondaryFontFamily(theme)} !important;
    font-weight: 700 !important;
    height: 60px !important;
    padding: 0.5rem 5rem 0.5rem 5rem !important;

    &:hover {
      background-color: ${({ theme }) =>
        theme.brand_name !== 'Achievement'
          ? theme?.branding_json?.color_8
          : '#0c4384'} !important;
    }
  }
`;

/**
 * @description CookieBanner Functional component, responsible for displaying the now infamous banner that lets users
 * know how we collect data using cookies on the site.
 * This shows up only while the user hasn't clicked on the "Accept" button.
 * Once the user does click on "Accept", it sets a cookie who's name comes from this variable `cookieName` and,
 * lasts  as long as the value here is set in days `cookieExpirationInDays`
 */
const CookieBanner: React.FC = () => {
  const buttonText = useTranslated('components.cookieBanner.buttonText');
  const bannerText = useTranslated('components.cookieBanner.bannerText');
  const noticeOfCollectionText = useTranslated(
    'components.cookieBanner.noticeOfCollection',
  );

  return (
    <StyledCookieBanner>
      <CookieConsent
        location={cookieBannerLocation}
        buttonText={buttonText}
        cookieName={cookieName}
        expires={cookieExpirationInDays}
      >
        {bannerText}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={noticeOfCollectionUrl}
        >
          {noticeOfCollectionText}
        </a>
      </CookieConsent>
    </StyledCookieBanner>
  );
};

export default CookieBanner;
