import { logLegacyReduxApiError } from '../../utils/errorLogger';
import { getJSON } from 'redux-api-middleware';
import { ErrorActionType } from '../../types/actions';
import { AnyAction } from 'redux';
import { RootState } from '..';
import { handleUnauthorizedError } from '../../services/index';
import { LandingActionType } from '../../types/actions';
const unauthorizedErrorCode = 401;

// to add future exception on redirection when get 401 code.
const redirectionActionExceptions = [LandingActionType.RECEIVE];

const detectActionExceptions = (actions: { type: string }[]) =>
  redirectionActionExceptions.reduce((acc, type) => {
    return acc || !!actions.filter((action) => action.type === type).length;
  }, false);

export const REDUX_API_FAILURE: AnyAction = {
  type: ErrorActionType.ERROR_ADD,
  payload: async (action: AnyAction, state: RootState, response: Response) => {
    const actions = action['@@redux-api-middleware/RSAA'].types;
    logLegacyReduxApiError({ action, state, response });
    if (
      response.status === unauthorizedErrorCode &&
      !detectActionExceptions(actions)
    ) {
      handleUnauthorizedError();
    }

    const json = await getJSON(response);
    return {
      json,
      status: response.status,
    };
  },
};

export const log_failure_with_request_data = (request: any): AnyAction => ({
  type: ErrorActionType.ERROR_ADD,
  payload: async (action: AnyAction, state: RootState, response: Response) => {
    logLegacyReduxApiError({ action, state, response, request });
    if (response.status === unauthorizedErrorCode) {
      handleUnauthorizedError();
    }
    const json = await getJSON(response);
    return {
      json,
      status: response.status,
    };
  },
});

export const addError = (payload: unknown) => ({ type: 'ERRORS/ADD', payload });
export const clearErrors = () => ({ type: 'ERRORS/CLEAR' });
