// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  StyledRaisedHands as RaisedHands,
  HeaderContainer,
  TitleContainer,
  Title,
  BodyContainer,
  Paragraph,
} from './NotificationModal.styles';
import Close from '../close';
import { StyledModal, CloseButton } from '../styledModal';
import { useTranslationWithPrefix } from '../../hooks';
import { actions } from '../../redux';

import errorIconUrl from './error-icon.svg';

class InvalidNotificationMessageTypeError extends Error {
  constructor(type) {
    super(`Invalid type for notification message: "${type}".`);
    this.name = 'InvalidNotificationMessageTypeError';
  }
}

const StyledMessageModal = styled(StyledModal)`
  max-width: 600px;
`;

const MessageModal = ({ title, message, icon, closeModal }) => {
  return (
    <StyledMessageModal
      isOpen={true}
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
      role="dialog"
    >
      <HeaderContainer>
        <TitleContainer>
          {icon}
          <Title>{title}</Title>
        </TitleContainer>
        <CloseButton onClick={closeModal}>
          <Close width={17} height={17} viewBox="0 0 17 17" />
        </CloseButton>
      </HeaderContainer>
      <BodyContainer>
        <Paragraph>{message}</Paragraph>
      </BodyContainer>
    </StyledMessageModal>
  );
};

export const NotificationModal = ({ participant, deleteAllMessages }) => {
  const { t } = useTranslationWithPrefix('components.notificationModal');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const messagesLength = participant.messages.length;
    setMessage(participant.messages[messagesLength - 1] || null);
  }, [participant.messages]);

  const closeModal = async () => {
    await deleteAllMessages(
      participant.enrollment_identifier,
      participant.participant_auth_token,
    );
    setMessage(null);
  };

  if (!message) {
    return null;
  }
  if (message.type === 'info') {
    return (
      <MessageModal
        title={t('congratulations')}
        message={message.message}
        icon={<RaisedHands role="img" />}
        closeModal={closeModal}
      />
    );
  }
  if (message.type === 'error') {
    return (
      <MessageModal
        title={<span style={{ color: '#BE322E' }}>{t('oops')}</span>}
        message={message.message}
        icon={<img src={errorIconUrl} alt={t('errorIcon')} />}
        closeModal={closeModal}
      />
    );
  }
  throw new InvalidNotificationMessageTypeError(message.type);
};

export default connect(
  ({ participant }) => ({ participant }),
  (dispatch) => ({
    deleteAllMessages: (enrollment_identifier, participant_auth_token) => {
      dispatch(
        actions.deleteAllMessages(
          enrollment_identifier,
          participant_auth_token,
        ),
      );
    },
  }),
)(NotificationModal);
