import { connect, Connect, TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from './index';

 // Note: Imported the 'store' like this to avoid circular
 // dependencies and having errors when the app try to
 // access 'store' values before it's initialization 
 // Redux store it's undefined when running tests due to lack of initialization
export const getReduxState = (): RootState | undefined =>
  require('./index').store?.getState();
/**
 * Export a typed version of connect with our root state
 * this is just a convience for not having to 
 * type it in your application code.
 */
export const typedConnect: Connect<RootState> = connect;
export const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;
