import { useEffect, useRef, useState } from 'react';

// Ideally we would just use `typeof window.setTimeout`, etc, but the Node.js
//   version of each function differs slightly from the browser version (for
//   instance, the Node one has a `__promisify__` method on it), and Jest runs
//   in a Node.js environment, so we need to support both.
type SetTimeout = (
  callback: (...args: any[]) => void,
  ms: number,
  ...args: any[]
) => number;
type ClearTimeout = (timeoutId: number) => void;

/**
 * Helper hook that calls `callback` on an interval. The difference between this
 * and `setInterval` is that this hook doesn't drift; with `setInterval`, it's
 * possible that your intervals are slightly longer than the given value, since
 * some implementations don't account for the execution time of the callback.
 * This hook is helpful in the cases in which precision is important.
 *
 * Note that there is no guarantee that the callback fires on every single
 * interval, since it's possible for the window/tab to be put to sleep by the
 * browser. Also, note that time precision is pretty low in modern browsers, so
 * the actual interval may be +/- a few milliseconds.
 *
 * If the interval is null, this hook does nothing.
 *
 * See: https://github.com/whatwg/html/issues/3151
 *
 * @param callback - The function to call
 * @param interval - The interval, in milliseconds, or null, in which case no
 *   timer is started.
 * @param [options]
 * @param [options.setTimeout] - The implementation of `setTimeout` to use.
 *   Defaults to `window.setTimeout`. Useful for mocking inside unit tests.
 * @param [options.clearTimeout] - The implementation of `clearTimeout` to use.
 *   Defaults to `window.clearTimeout`. Useful for mocking inside unit tests.
 */
const useExactInterval = (
  callback: () => void,
  interval: number | null,
  {
    setTimeout = window.setTimeout,
    clearTimeout = window.clearTimeout,
  }: {
    setTimeout?: SetTimeout;
    clearTimeout?: ClearTimeout;
  } = {},
) => {
  const [initialTimestamp] = useState(Date.now());

  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (interval && interval !== 0) {
      let timerId: number;
      const startTimer = () => {
        const now = Date.now();
        let msUntilNextTick = interval - ((now - initialTimestamp) % interval);
        timerId = setTimeout(() => {
          callbackRef.current();
          startTimer();
        }, msUntilNextTick);
      };
      startTimer();
      return () => {
        if (timerId) {
          clearTimeout(timerId);
        }
      };
    }
  }, [interval, initialTimestamp, setTimeout, clearTimeout]);
};
export default useExactInterval;
