import _ from 'lodash';
import { AnyAction } from 'redux';
import { LandingActionType } from '../../types/actions';
import { LANDING_STATE } from '../../types/states';

const defaultLandingState: LANDING_STATE = {
  layout: '',
  nodes: [],
};

export const landing = (
  state: LANDING_STATE = defaultLandingState,
  { type, payload }: AnyAction,
): LANDING_STATE => {
  switch (type) {
    case LandingActionType.RECEIVE:
      return { ...state, ..._.pick(payload, ['nodes', 'layout']) };
    default:
      return state;
  }
};
