import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

export type ErrorMsg = {
  json?: {
    errors?: string[] | { message: string }[];
    message?: string;
  };
  status?: number;
};

export type ErrorMessageProps = {
  error?: ErrorMsg;
  message?: string;
};

const ErrorMessageWrapper = styled.p`
  display: flex;
  padding-left: 5px;
  text-align: left;
`;

const Error = ({ message = '' }: { message: string }) => (
  <ErrorMessageWrapper>
    <span role="img" aria-label="error icon" className="mr10">
      ⚠️️
    </span>
    {message}
  </ErrorMessageWrapper>
);

export const getErrorMessage = (error?: ErrorMsg): string | undefined => {
  if (!error) {
    return undefined;
  }

  const errorJson = error.json;
  if (errorJson?.message) {
    return errorJson.message;
  }

  const errors = errorJson?.errors;
  if (typeof errors?.[0] === 'object') {
    return errors[0].message;
  }

  return errors?.[0];
};

/**
 * @desc Parses and returns error message objects.
 */
const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, message }) => {
  const { t } = useTranslation();
  const DEFAULT_MESSAGE = t('components.error.defaultError');
  const SERVER_ERROR = t('components.error.serverError');
  const status = error?.status;

  if (status && status >= 500) {
    return <Error message={SERVER_ERROR} />;
  }

  if (message || error) {
    return (
      <Error message={message || getErrorMessage(error) || DEFAULT_MESSAGE} />
    );
  }
  return <div />;
};

export default ErrorMessage;
