import { globalTranslate } from '../../i18n';

export const errors = (
  state = [],
  {
    type,
    payload = {
      json: globalTranslate('components.error.errorOccurred'),
      status: null,
    },
  }: { type: string; payload: { json: string; status: number | null } },
) => {
  switch (type) {
    case 'ERRORS/ADD':
      return [payload].concat(state);
    case 'ERRORS/CLEAR':
      return [];
    default:
      return state;
  }
};
