//@ts-ignore
import CuriousPanda from '@evidation/curious-panda';
import type { CuriousPandaProps } from '@evidation/curious-panda';
import React from 'react';
import { useLanguageContext } from '../contexts/LanguageContext';

const LocalCuriousPanda = (props: CuriousPandaProps) => {
  // Need a default for the test environment, where we don't necessarily have a
  // language context.
  // @ts-ignore
  // todo fix the lanague context to properly describe the state.
  const locale = useLanguageContext()?.locale;
  return (
    <CuriousPanda autoComplete="off" translationLocale={locale} {...props} />
  );
};

export {
  BillboardNavigation,
  GetFieldDictionary,
  SubmissionError,
} from '@evidation/curious-panda';

export default LocalCuriousPanda;
