import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Spacer } from '@evidation/ui';

import BasicPageWrapper from './basicPageWrapper';
import Button from './button';
import ContactModal from './contactModal';

type ErrorProps = {
  title?: React.ReactNode;
  message?: React.ReactNode;
  buttonType?: 'contact-us' | 'return-to-dashboard';
};

/**
 * Generic error page. You may optionally pass `title` and/or `text` props to
 *   customize the message.
 */
const Error: React.FC<ErrorProps> = ({
  title,
  message,
  buttonType = 'contact-us',
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  // Explicitly check for `undefined` because we want to allow overriding with
  //   null values.
  if (title === undefined) {
    title = t('components.error.errorOccurred');
  }
  if (message === undefined) {
    message = <Trans i18nKey="components.error.somethingWrong" t={t} />;
  } else if (typeof message === 'string') {
    message = <p>{message}</p>;
  }

  return (
    // @ts-ignore
    <BasicPageWrapper title={title}>
      {message}
      <Spacer size={10} />
      {buttonType === 'contact-us' ? (
        <ContactModal>
          {({ showModal }: { showModal: () => void }) => (
            <Button onClick={showModal} secondary>
              {t('components.common.contactUs')}
            </Button>
          )}
        </ContactModal>
      ) : (
        <Button onClick={() => history.push('/manage/dashboard')} secondary>
          {t('components.common.returnDashboard')}
        </Button>
      )}
      <Spacer size={10} />
    </BasicPageWrapper>
  );
};
export default Error;
