import i18n from 'i18next';

/**
 * @description Utility function to translate keys in non-react component function. (i.e.:
 * validation functions for form fields) This will set up the namespace needed for the
 * translations. Warning this requires that a global instance of i18next be initialized
 * otherwise only the key will be returned
 * @param {String} key - identifies the string to return for translation
 * @param {Object} options - i18next options for translations
 * @returns {String}
 */
export const globalTranslate: typeof i18n.t = (key, options) =>
  i18n.isInitialized ? i18n.t(key, options) : key;

export const nativeNames = {
  en: 'English',
  enAU: 'English - Australia',
  enCA: 'English - Canada',
  enGB: 'English - Great Britain',
  enUS: 'English',
  es: 'Español',
  esES: 'Español - España',
  esMX: 'Español - México',
  esUS: 'Español - USA',
  fr: 'Français',
  frCA: 'Français (Canada)',
  de: 'Deutsch',
  it: 'Italiano',
};
