import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux';

const connector = connect(
  ({ meta }: RootState) => ({
    brandName: meta?.brand?.brand_name || 'Evidation',
    companyName:
      meta?.brand?.branding_json?.company_name ||
      meta?.brand?.brand_name ||
      'Evidation',
    companyUrl: meta?.brand?.branding_json?.company_url || 'myachievement.com',
    emailDomain: meta?.brand?.branding_json?.email_domain,
  }),
  null,
  null,
  {
    getDisplayName: () => 'WithBrand',
  },
);

const withBrand = <Props extends ConnectedProps<typeof connector>>(
  WrappedComponent: React.JSXElementConstructor<Props>,
) => {
  // TypeScript complains about a type mismatch even though there shouldn't be
  //   one. This component is simple enough that this should be fine.
  // @ts-expect-error
  return connector(WrappedComponent);
};

export default withBrand;
