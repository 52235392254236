import { css } from 'styled-components';

export default css`
  html {
    height: 100%;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    height: 100%;
    font-family: 'Heebo', sans-serif;
    color: #5b6b7d;
    font-size: 1rem;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Adamina', serif;
    color: #2a3f53;
  }

  h1 {
    font-size: ${30 / 16}rem;
    font-weight: 400;
    line-height: 40px;
    margin: 0;
  }

  h2 {
    font-size: ${24 / 16}rem;
    font-weight: 400;
    line-height: 35px;
    margin: 0;
  }

  h3 {
    font-size: ${21 / 16}rem;
    font-weight: 400;
    margin: 0;
  }

  h4 {
    font-size: ${18 / 16}rem;
    font-weight: 400;
    margin: 0;
  }

  p {
    font-size: 1rem;
  }

  p.small,
  a.small {
    font-size: ${14 / 16}rem;
  }

  a {
    color: #06466d;
    text-decoration: underline;
  }

  input {
    transition: all 0.3s ease-in-out;
    background-color: #fcfcfc !important;
  }

  input:focus {
    outline: none !important;
    box-shadow: 0 0 5px #0f5fbc !important;
    border: 1px solid #0f5fbc !important;
    color: #2a3f53 !important;
  }

  label {
    color: rgba(64, 64, 64, 1);
  }

  /* ios safari smooth scroll */
  * {
    -webkit-overflow-scrolling: touch;
  }
`;
