// @ts-nocheck
import { Container, EnrollmentContent, H2 } from './index.ts';

import PropTypes from 'prop-types';
import React from 'react';

const BasicPageLayout = ({ title, children }) => {
  return (
    <Container pt={50} style={{ flex: '1' }}>
      <EnrollmentContent pl={30} pr={30}>
        <H2 ta="center">{title}</H2>
        {children}
      </EnrollmentContent>
    </Container>
  );
};

BasicPageLayout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicPageLayout;
