import { useTranslation } from 'react-i18next';
import type { UseTranslationOptions } from 'react-i18next';

/**
 * A convenience hook that is identical to `useTranslation` except that it
 * prepends all keys passed to `t` with the given prefix.
 *
 * @param {string} prefix The prefix to prepend to all i18n keys passed to `t`.
 * @returns An object identical to that which is returned by `useTranslation`.
 * @deprecated Instead, call `useTranslation` with the `keyPrefix` option.
 *   This will be removed as part of SPP-1605.
 */
export const useTranslationWithPrefix = (
  prefix: string,
  ns?: string,
  options?: UseTranslationOptions,
) => {
  return useTranslation(ns, {
    ...options,
    keyPrefix: prefix,
  });
};
