import React from 'react';
import Reaptcha, { ReaptchaProps } from '@panalbish/reaptcha-enterprise';
import { getIsWebview } from '../services';

type Props = Omit<ReaptchaProps, 'sitekey' | 'enterprise'>;

/**
 * Thin component that wraps Reaptcha with the correct site key for
 * checkbox-based validation.
 */
export default function RecaptchaCheckbox(props: Props) {
  const isWebView = getIsWebview();
  const siteKey = window.env.RECAPTCHA_ENTERPRISE_CHECKBOX_SITE_KEY;
  if (isWebView || !siteKey) return null;
  return <Reaptcha sitekey={siteKey} enterprise={true} {...props} />;
}
