import {
  COOKIE_PARTICIPANT_AUTH,
  COOKIE_PORTAL_EMAIL,
  COOKIE_ACM_ID,
} from '../constants';
import api from '../services/index';
import logger from './errorLogger';

/**
 * @description Deletes billboard related localStorage items, as well as, cookie(s)
 * and resets the user back to the login screen upon a new visit.
 * @param {string} slugId - the study slug, which can be found in the url,
 * needed to delete the cookie that keeps track of our progress and state in the study.
 * @returns {boolean}
 */
export const simpleUserLogout = (slugId = '') => {
  if (!slugId) {
    return false;
  }

  try {
    api.cookieStorage.remove();
    api.cookie.remove(`${slugId}_`);
    api.cookie.remove(COOKIE_PARTICIPANT_AUTH);
    api.cookie.remove(COOKIE_PORTAL_EMAIL, {
      domain: '.myachievement.com',
    });
    api.cookie.remove(COOKIE_ACM_ID, {
      domain: '.myachievement.com',
    });
  } catch (error: any) {
    logger.error(error);
    return false;
  }
  return true;
};
