import { addDays, differenceInSeconds, isValid } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import useExactInterval from '../../hooks/useExactInterval';
import { RootState, store } from '../../redux';
// Importing actions directly from `redux` causes a circular import, so we have
// to be more specific.
import * as actions from '../../redux/participant/actions';
import { getSessionExpiredRedirectUrl } from '../../services';
import { simpleUserLogout as realSimpleUserLogout } from '../../utils/simpleUserLogout';

import AuthExpirationModal from './authExpirationModal';

type ParticipantAuthTokenExpirationHandlerProps = ConnectedProps<
  typeof Connector
> & {
  simpleUserLogout: (slug: string) => void;
};

/**
 * The number of minutes before token expiration at which to show the session
 * expiration modal to the user.
 */
const PRE_EXPIRATION_MINUTES = 5;

export class InvalidExpiresHeaderValueError extends Error {
  constructor(value: string) {
    super(
      `Invalid value for the participant auth token expiration header: "${value}".`,
    );
  }
}

/**
 * Parses the raw value of the participant auth token expiration header into a
 * more semantic value. A null return value implies that the token does not
 * expire. This should never be called with an empty or invalid value.
 *
 * @param {string} headerVal - The value of the participant auth token
 *   expiration header
 * @returns {Date | null} - If the value is the literal "null", returns null;
 *   otherwise, parses the date and returns a Date instance.
 */
export const parseParticipantAuthTokenExpiresHeader = (headerVal: string) => {
  if (headerVal === 'null') {
    return null;
  }
  const date = new Date(headerVal);
  if (!isValid(date)) {
    throw new InvalidExpiresHeaderValueError(headerVal);
  }
  return date;
};

export const storeAuthTokenExpiration = (expiration: Date) => {
  store.dispatch(actions.setAuthTokenExpiration(expiration));
};

export const ParticipantAuthTokenExpirationHandler: FC<ParticipantAuthTokenExpirationHandlerProps> =
  ({
    authTokenExpiration,
    participantAuthToken,
    enrollmentIdentifier,
    slug,
    authProvider,
    logoutUrl,
    destroyAuthToken,
    setAuthTokenExpiration,
    logoutUser,
    getUser,
    simpleUserLogout = realSimpleUserLogout,
  }) => {
    const [shouldShowPrompt, setShouldShowPrompt] = useState<boolean>(false);
    // This is only necessary when the prompt is shown.
    const [secondsBeforeExpiration, setSecondsBeforeExpiration] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    // Note: This was copied from header.js because there isn't a good way to
    // share this functionality between the components without having the latest
    // version of react-redux with hooks.
    const logout = useCallback(async () => {
      setIsLoading(true);
      await destroyAuthToken(enrollmentIdentifier, participantAuthToken);
      if (authProvider && logoutUrl) {
        simpleUserLogout(slug);
        window.location.href = getSessionExpiredRedirectUrl();
      } else {
        await logoutUser({ sessionExpired: true });
      }
    }, [
      enrollmentIdentifier,
      participantAuthToken,
      destroyAuthToken,
      authProvider,
      logoutUrl,
      slug,
      logoutUser,
      simpleUserLogout,
    ]);

    const tick = useCallback(() => {
      if (!authTokenExpiration) return;

      const secondsLeft = Math.max(
        differenceInSeconds(authTokenExpiration, new Date()),
        0,
      );
      if (!isLoading) {
        if (secondsLeft === 0) {
          logout();
        }
        if (!shouldShowPrompt && secondsLeft <= PRE_EXPIRATION_MINUTES * 60) {
          setShouldShowPrompt(true);
        }
      }

      setSecondsBeforeExpiration(Math.ceil(secondsLeft));
    }, [authTokenExpiration, shouldShowPrompt, isLoading, logout]);
    useExactInterval(tick, 1000);

    const extendSession = async () => {
      // Temporarily set auth token expiration to a far-out date so that the
      // modal doesn't re-appear before the `getUser` call is finished.
      const tempDate = addDays(new Date(), 30);
      setAuthTokenExpiration(tempDate);
      setShouldShowPrompt(false);
      await getUser(enrollmentIdentifier, participantAuthToken);
    };

    // We tick once after mount to prevent a possible one-second delay before
    // anything happens.
    useEffect(() => {
      tick();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (shouldShowPrompt) {
      return (
        <AuthExpirationModal
          isLoading={isLoading}
          secondsBeforeExpiration={secondsBeforeExpiration}
          closeModal={() => setShouldShowPrompt(false)}
          onLogOut={() => logout()}
          onStayLoggedIn={() => extendSession()}
        />
      );
    }
    return null;
  };
// Ideally, this would use hooks instead of `connect`, but our version of
// react-redux doesn't have the `useSelector` hook, so this is the next best
// thing.
const Connector = connect(
  (state: RootState) => ({
    authTokenExpiration: state.participant.auth_token_expiration,
    participantAuthToken: state.participant.participant_auth_token,
    enrollmentIdentifier: state.participant.enrollment_identifier,
    slug: state.meta.slug,
    authProvider: state.meta.authentication.provider,
    logoutUrl: state.meta.authentication.details?.logout,
  }),
  {
    destroyAuthToken: actions.destroyAuthToken,
    setAuthTokenExpiration: actions.setAuthTokenExpiration,
    logoutUser: actions.logoutUser,
    getUser: actions.getUser,
  },
);
export default Connector(ParticipantAuthTokenExpirationHandler);
