import { css } from 'styled-components';
import PPMIFonts from '../assets/ppmi/fonts';
import EvidationFonts from '../assets/evidation/fonts';
import { primaryFontFamily, secondaryFontFamily } from '../utils/brandHelpers';

// evidation_json = {
//   'color_1' => white, #page background (body)
//   'color_2' => white, #header/footer background
//   'color_3' => evidation_slate, #nav/footer font color
//   'color_4' => blue, #button background, show more font color
//   'color_5' => evidation_slate, #headline (Hero banner font color, )
//   'color_6' => white, #button font color
//   'color_7' => blue, #underlines (hr?)
//   'color_8' => dark blue, #button hover background
//   'color_9' => evidation_slate, #hiw header font color
//   'color_10' => evidation_slate, #body global color
//   'color_11' => blue, #hiw dots (may not be usable right now)
//   'color_12' => evidation_slate #FAQ header color
//   'font_1' => "'Adamina',serif",
//   'font_2' => "'Heebo', sans-serif"
// }

const BrandStyles = css`
  ${({ theme }) => {
    if (theme?.brand_name === 'PPMI') {
      return PPMIFonts;
    }

    if (theme?.brand_name === 'Evidation') {
      return EvidationFonts;
    }

    return '';
  }}

  body {
    background-color: ${({ theme }) => theme?.branding_json?.color_1};
    font-family: ${({ theme }) =>
      theme?.brand_name === 'PPMI'
        ? primaryFontFamily(theme)
        : secondaryFontFamily(theme)};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ theme }) =>
      theme.brand_name === 'PPMI'
        ? secondaryFontFamily(theme)
        : primaryFontFamily(theme, { fontWeight: 'bold' })};
    color: ${({ theme }) => theme?.branding_json?.color_5};

    > span {
      font-family: inherit !important;
      font-size: inherit !important;
      font-color: inherit !important;
    }
  }

  a.large {
    font-size: ${18 / 16}rem;
  }

  button {
    font-family: ${({ theme }) =>
      theme?.brand_name === 'PPMI'
        ? primaryFontFamily(theme)
        : secondaryFontFamily(theme)};
    color: ${({ theme }) => theme?.branding_json?.color_10};
  }

  textarea,
  input {
    font-family: ${({ theme }) =>
      theme?.brand_name === 'PPMI'
        ? primaryFontFamily(theme, { fontWeight: 'light' })
        : secondaryFontFamily(theme, { fontWeight: 'light' })};
  }

  hr {
    border-top: 1px solid ${({ theme }) => theme?.branding_json?.color_7};
  }
`;

export default BrandStyles;
