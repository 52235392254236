import { Participant } from '../../types/api';
import { ParticipantActionType } from '../../types/actions';
import { AnyAction } from 'redux';

const defaultParticipantValues: Participant = {
  auth_token_expiration: null,
  connected_apps: [],
  email: '',
  enrollment_identifier: '',
  loading: false,
  messages: [],
  participant_auth_token: '',
  phone_number: '',
  preferred_contact_method: '',
  preferred_contact_time: '',
  status: '',
  tags: [],
  compensation: [],
  pinned_contributions: [],
  nodes: [],
  layout: '',
};

interface UserLogout extends AnyAction {
  type: ParticipantActionType.USER_LOGOUT;
  payload: string;
}

interface UserSetAuhtTokenExpiration extends AnyAction {
  type: ParticipantActionType.USER_SET_AUTH_TOKEN_EXPIRATION;
  payload: string;
}

interface UserLogin extends AnyAction {
  type: ParticipantActionType.USER_LOGIN;
  payload: string;
}

interface UserUpdateACM extends AnyAction {
  type: ParticipantActionType.USER_UPDATE_ACM;
  payload: string;
}
interface SurveySubmitSuccess extends AnyAction {
  type: ParticipantActionType.SURVEY_SUBMIT_SUCCESS;
  payload: string;
}

function matchUserLogout(action: AnyAction): action is UserLogout {
  return action.type === ParticipantActionType.USER_LOGOUT;
}

function matchUserSetAuhtTokenExpiration(
  action: AnyAction,
): action is UserSetAuhtTokenExpiration {
  return action.type === ParticipantActionType.USER_SET_AUTH_TOKEN_EXPIRATION;
}

function matchUserLogin(action: AnyAction): action is UserLogin {
  return action.type === ParticipantActionType.USER_LOGIN;
}

function matchUserUpdateACM(action: AnyAction): action is UserUpdateACM {
  return action.type === ParticipantActionType.USER_UPDATE_ACM;
}

function matchSurveySubmitSuccess(
  action: AnyAction,
): action is SurveySubmitSuccess {
  return action.type === ParticipantActionType.SURVEY_SUBMIT_SUCCESS;
}

export const participant = (
  state = defaultParticipantValues,
  { type, payload }: AnyAction,
): Participant => {
  if (matchUserLogout({ type })) {
    return { ...defaultParticipantValues };
  } else if (matchUserSetAuhtTokenExpiration({ type })) {
    return { ...state, auth_token_expiration: payload };
  } else if (
    matchUserLogin({ type }) ||
    matchUserUpdateACM({ type }) ||
    matchSurveySubmitSuccess({ type })
  ) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
