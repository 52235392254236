import {
  browserName,
  browserVersion,
  engineName,
  engineVersion,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';

const userAgentInfo = {
  browser: {
    name: browserName,
    version: browserVersion,
  },
  engine: {
    name: engineName,
    version: engineVersion,
  },
  device: {
    vendor: mobileVendor === 'none' ? null : mobileVendor,
    name: mobileModel === 'none' ? null : mobileModel,
  },
  os: {
    name: osName,
    version: osVersion,
  },
};
export default userAgentInfo;
