import React, { ErrorInfo, ReactNode } from 'react';
import logger from './utils/errorLogger';
import Error from './components/error';

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<Props> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    logger.error(error);
  }
  render() {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
