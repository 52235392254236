// @ts-nocheck
import { object, string } from 'prop-types';

import { Component } from 'react';
import gtmParts from 'react-google-tag-manager';

class GoogleTagManager extends Component {
  componentDidMount() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false,
    });

    if (!window[this.props.dataLayerName]) {
      // eslint-disable-next-line
      eval(gtm.scriptAsHTML().replace(/<\/?script>/g, ''));
    }
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false,
    });

    return gtm.noScriptAsReact();
  }
}

GoogleTagManager.propTypes = {
  gtmId: string.isRequired,
  dataLayerName: string,
  additionalEvents: object,
  previewVariables: string,
  scriptId: string,
};

export default GoogleTagManager;
