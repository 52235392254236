// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CuriousPanda from './CuriousPanda';
import Modal from './styledModal';
import PropTypes from 'prop-types';
import { STUDY_ID } from '../constants';
import _ from 'lodash';
import api from '../services';
import styled from 'styled-components';
import { StyledButton } from './BillboardNavigation';
import userAgentInfo from '../utils/userAgentInfo';
import { withTranslation } from 'react-i18next';

const Dialog = styled.div`
  box-sizing: border-box;
  position: relative;
  background: white;
  padding: 0 10;
  color: #333;
  border-radius: 3;
`;

const BtnGroup = styled.div`
  display: flex;
  margin: 20 0;

  button {
    padding: 14px 20px;
    font-size: 1rem;
    height: 60px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #eff2f4;
    background: #fcfcfc;
    color: #cccccc;
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;

    &.active {
      color: #000;
      outline: solid 1px #698aab6b;
      z-index: 2;
    }
  }
`;

class ContactModal extends Component {
  constructor(props) {
    super(props);

    const participant = api.cookieStorage.get();
    const { t } = props;
    const contactModalHeading = t('components.contactModal.header.default');

    this.state = {
      isShowingModal: false,
      opacity: 0,
      message: ``,
      header: props.contact_modal_heading || contactModalHeading,
      preferredMethod: props.contact_preferred_method,
      loading: false,
      participant,
    };
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  afterOpen = () =>
    setTimeout(() => {
      this.setState({ opacity: 1 });
    });

  handleClose() {
    this.setState({ isShowingModal: false, message: ``, loading: false });
    this.props.onClose();
  }

  submit(data) {
    const { t } = this.props;
    const { participant } = this.state;
    const participant_auth_token = _.has(participant, `participant_auth_token`)
      ? participant.participant_auth_token
      : ``;

    const payload = {
      ...participant,
      ...data,
      study_slug: STUDY_ID,
      client_info: userAgentInfo,
    };

    this.setState({ loading: true }, () => {
      api.triage
        .post('support_requests', payload, participant_auth_token)
        .then(({ data }) => {
          this.setState({
            isShowingModal: true,
            header: data.success
              ? t('components.contactModal.header.success')
              : t('components.contactModal.header.error'),
            message: data.success
              ? t('components.contactModal.message.success')
              : data.error ||
                data.message ||
                t('components.contactModal.message.error'),
          });
        })
        .catch(({ response }) => {
          this.setState({
            isShowingModal: true,
            header: t('components.contactModal.header.error'),
            message:
              response?.data?.errors?.[0] ||
              t('components.contactModal.message.error'),
          });
        });
    });
  }

  showModal = () => this.setState({ isShowingModal: true });

  render() {
    const { preferredMethod, header, message, isShowingModal, loading } =
      this.state;
    const { children, contact_phone_number, t } = this.props;
    let { contact_modal_message, contact_modal_heading } = this.props;
    const text = {
      heading: t('components.contactModal.header.default'),
      message: t('components.contactModal.message.default'),
    };
    contact_modal_message = contact_modal_message || text.message;
    contact_modal_heading = contact_modal_heading || text.heading;

    const form = [
      {
        input: [
          {
            name: 'email',
            label: t('components.contactModal.body.form.emailLabel'),
            inputStyle: 'achievement',
            interface: 'email',
            required: true,
          },
          {
            inputStyle: 'achievement',
            name: 'request_content',
            label: t('components.contactModal.body.form.requestLabel'),
            interface: 'textarea',
            required: true,
          },
        ],
      },
    ];

    const initialValues = {
      email:
        this.state.participant?.email &&
        // If the email is masked, don't attempt to use it.
        !this.state.participant.email.includes('*')
          ? this.state.participant.email
          : ``,
    };

    return (
      <React.Fragment>
        {children({ showModal: this.showModal })}
        <Modal
          header={message === `` ? contact_modal_heading : header}
          isOpen={isShowingModal}
          closeModal={this.handleClose}
          afterOpen={this.afterOpen}
        >
          {message === `` && (
            <Dialog>
              <p className="text-center mb20">{contact_modal_message}</p>
              <div name="contactForm" className="multiple-choice">
                {preferredMethod && contact_phone_number !== `` && (
                  <fieldset className="mb20">
                    <div className="text-small">
                      {t('components.contactModal.body.preferredContactMethod')}
                    </div>
                    <BtnGroup>
                      <button
                        className={preferredMethod === `Email` ? `active` : ``}
                        onClick={() =>
                          this.setState({
                            preferredMethod: `Email`,
                            isShowingModal: true,
                          })
                        }
                      >
                        {t('components.contactModal.body.email')}
                      </button>
                      <button
                        className={preferredMethod === `Phone` ? `active` : ``}
                        onClick={() =>
                          this.setState({
                            preferredMethod: `Phone`,
                            isShowingModal: true,
                          })
                        }
                      >
                        {t('components.contactModal.body.phone')}
                      </button>
                    </BtnGroup>
                  </fieldset>
                )}
                <div className="clear" />
                {preferredMethod === `Phone` && (
                  <h2 className="text-center mb20">
                    {t('components.contactModal.body.callSupport', {
                      supportNumber: contact_phone_number,
                    })}
                  </h2>
                )}
                {preferredMethod === `Email` && (
                  <CuriousPanda
                    form="contact_us"
                    onSubmit={this.submit}
                    schema={form}
                    initialValues={initialValues}
                  >
                    {({ valid, pristine, submitting }) => {
                      // Logic to determine if submit should be deactivated
                      // in this instance we are checking to see if we have initial values defined
                      // as If we have already populated the form (for CRF ONLY), it is ready to be submitted
                      // most other cases do not require this.
                      const allowSubmit =
                        !valid ||
                        submitting ||
                        (_.isEmpty(initialValues) && pristine);

                      return (
                        <StyledButton
                          isLoading={submitting || loading}
                          buttonStyle="achievement"
                          id="t-submit_button"
                          type="submit"
                          disabled={allowSubmit}
                        >
                          {t('components.contactModal.body.sendRequest')}
                        </StyledButton>
                      );
                    }}
                  </CuriousPanda>
                )}
              </div>
            </Dialog>
          )}
          {message !== `` && <p className="text-center mb20">{message}</p>}
        </Modal>
      </React.Fragment>
    );
  }
}

ContactModal.defaultProps = {
  contact_phone_number: '',
  contact_preferred_method: 'Email',
  onClose: () => {},
};

ContactModal.propTypes = {
  onClose: PropTypes.func,
  contact_phone_number: PropTypes.string,
  children: PropTypes.func,
};

export default connect((state) => ({
  contact_phone_number: state.meta.contact_phone_number,
}))(withTranslation()(ContactModal));
