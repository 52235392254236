// @ts-nocheck
import { MarkdownHtml } from '@evidation/form-elements';
import React from 'react';
import { Trans } from 'react-i18next';
import Button from '../../components/button';
import ContactModal from '../../components/contactModal';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '../../components/BillboardNavigation';
import { toastr } from 'react-redux-toastr';
import BasicPageWrapper from '../../components/basicPageWrapper';
import { ENROLLMENT_STATUSES } from '../../constants';

const FailurePage = ({ content, getAllData, history, status = null }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'routes.verifyIdentity.components.failurePage',
  });
  const { t: tUnprefixed } = useTranslation();

  const text = {
    title: t('title'),
    contactButton: t('contactButton'),
    button:
      status === ENROLLMENT_STATUSES.suspended
        ? t('suspendedButton')
        : tUnprefixed('components.common.returnDashboard'),
    errorMessage: t('errorMessage'),
  };

  const handleOnClick = async () => {
    try {
      await getAllData();
      history.push(
        status === ENROLLMENT_STATUSES.suspended
          ? '/manage/settings'
          : '/dashboard',
      );
    } catch (err) {
      toastr.error(text.errorMessage);
      throw err;
    }
  };

  return (
    <BasicPageWrapper>
      <h2>{content?.failure_page_title || text.title}</h2>
      {content?.failure_page_confirm_text ? (
        <MarkdownHtml>{content.failure_page_confirm_text}</MarkdownHtml>
      ) : (
        <p>
          <Trans t={t} i18nKey={'confirmText'} />
        </p>
      )}
      <StyledButton onClick={handleOnClick}>
        {content?.failure_page_confirm_button_text || text.button}
      </StyledButton>
      <ContactModal>
        {({ showModal }) => (
          <Button onClick={showModal} secondary className={'mb10 mt10'}>
            {content?.failure_page_contact_button_text || text.contactButton}
          </Button>
        )}
      </ContactModal>
    </BasicPageWrapper>
  );
};

export default FailurePage;
