// @ts-nocheck
import { BigBlueButton } from '../../../components/header/headerLinks';
import { Button } from '@evidation/form-elements';
import CuriousPanda from '../../../components/CuriousPanda';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Recaptcha from '../../../components/recaptcha';
import { RECAPTCHA_VERSIONS } from '../../../constants';
import { useSetLocaleOverride, useTranslated } from '../../../hooks';
import { useLanguageContext } from '../../../contexts';
import { getDisplayTextFromLocale } from '../../../utils';

const LoginForm = ({
  submit,
  config,
  recaptchaEnabled,
  formAction,
  ...rest
}) => {
  const localOverride = useSetLocaleOverride();
  const locale = useLanguageContext()?.locale;
  const trueLocale = locale || localOverride;

  const [clearAllValidations, setClearAllValidations] = useState(false);
  const [localeText, setLocaleText] = useState(() =>
    getDisplayTextFromLocale(trueLocale),
  );

  // useEffect is used to reset the client validations.
  // When 'useLanguageContext()?.locale' change,
  // useEffect is trigged and set the new locale language in the 'localeText' state variable and 'clearAllValidations' would be 'true' to remove the client validations
  // then useEffect is trigged again by 'setLocaleText' which calls 'setClearAllValidations(false)' to set the original client validations.
  React.useEffect(() => {
    if (localeText !== locale) {
      setLocaleText(locale);
    }
    setClearAllValidations(localeText !== locale);
  }, [locale, localeText]);

  const forgotPasswordText = useTranslated(
    'components.loginForm.forgotPassword',
  );
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  recaptchaEnabled = !!(recaptchaEnabled && formAction);

  const onSubmit = (data) => {
    if (recaptchaEnabled) {
      data = { ...data, recaptcha_token: recaptchaToken };
    }
    submit(data);
  };
  const forgotPasswordLink =
    window.env.REACT_APP_ACHIEVEMENT_FORGOT_PASSWORD_URL;
  return (
    <CuriousPanda
      enableReinitialize={true}
      destroyOnUnmount={true}
      keepDirtyOnReinitialize={true}
      forceUnregisterOnUnmount={false}
      pure={true}
      onSubmit={onSubmit}
      clearAllValidations={clearAllValidations}
      {...rest}
    >
      {({ invalid, pristine, submitting }) => {
        const { hero_button, isLoading, displayPasswordLink, hasAcmCookie } =
          config;
        // Logic to determine if submit should be deactivated
        // in this instance we are checking to see if we have initial values defined
        // as If we have already populated the form (for CRF ONLY), it is ready to be submitted
        // most other cases do not require this.
        const enabled = !!(
          hasAcmCookie ||
          ((_.isEmpty(rest.initialValues)
            ? pristine || submitting
            : !invalid || submitting) &&
            // If we're using the reCaptcha checkbox, make sure the token is present.
            (!recaptchaEnabled || recaptchaToken))
        );

        return (
          <React.Fragment>
            {recaptchaEnabled && (
              <Recaptcha
                action={formAction}
                onVerify={(token) => {
                  setRecaptchaToken(token);
                }}
                onExpire={() => {
                  setRecaptchaToken(null);
                }}
              />
            )}
            <BigBlueButton
              isLoading={submitting || isLoading}
              buttonStyle="achievement"
              id={`t-submit_button_${rest.form}`}
              type="submit"
              disabled={!enabled}
              hero={rest.hero}
            >
              {hero_button}
            </BigBlueButton>
            {displayPasswordLink && (
              <Button
                className="mt5"
                buttonStyle="achievement_text"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.replace(forgotPasswordLink);
                }}
              >
                {forgotPasswordText}
              </Button>
            )}
          </React.Fragment>
        );
      }}
    </CuriousPanda>
  );
};

LoginForm.defaultProps = {};

LoginForm.propTypes = {};

export default connect(({ meta }) => ({
  recaptchaEnabled: meta?.recaptcha_version === RECAPTCHA_VERSIONS.CHECKBOX,
}))(LoginForm);
