import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {
  browserName,
  browserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';
import { META } from '../types/api';

/**
 * Returns a unique ID for the visitor determined by their browser's fingerprint.
 */
export const getVisitorId = async () => {
  if (process.env.NODE_ENV === 'test') return 'TEST-VISITOR-ID';
  const fingerprint = await FingerprintJS.load();
  const fingerprintData = await fingerprint.get();
  return fingerprintData.visitorId;
};

/**
 * Returns details about the user's browser, screen, and time zone.
 */
export const getUserAgentDetails = () => {
  return {
    browser: {
      name: browserName,
      version: browserVersion,
      isMobile,
    },
    screenRes: {
      width: window.screen.width,
      height: window.screen.height,
    },
    device: {
      vendor: mobileVendor === 'none' ? null : mobileVendor,
      model: mobileModel === 'none' ? null : mobileModel,
    },
    os: {
      name: osName,
      version: osVersion,
    },
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

export const userAgentMetaHeaders = (meta?: META) => {
  if (!meta) {
    return {};
  }
  const headers: { [key: string]: any } = {
    'X-EVI-Browser-Name': meta.userAgent?.browser.name,
    'X-EVI-Browser-Version': meta.userAgent?.browser.version,
    'X-EVI-Browser-Is-Mobile': meta.userAgent?.browser.isMobile,
    'X-EVI-Screen-Width': meta.userAgent?.screenRes.width,
    'X-EVI-Screen-Height': meta.userAgent?.screenRes.height,
    'X-EVI-User-Time-Zone': meta.userAgent?.timeZone,
    'X-EVI-OS-Name': meta.userAgent?.os.name,
    'X-EVI-OS-Version': meta.userAgent?.os.version,
    'X-EVI-Visitor-ID': meta.visitorId,
  };
  if (meta.userAgent?.device.vendor) {
    headers['X-EVI-Device-Vendor'] = meta.userAgent.device.vendor;
  }
  if (meta.userAgent?.device.model) {
    headers['X-EVI-Device-Model'] = meta.userAgent.device.model;
  }
  return headers;
};
