// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StyledModal from './styledModal';
import api from '../services';
import { logError } from '../utils/errorLogger';
import CuriousPanda from './CuriousPanda';
import { StyledButton } from './BillboardNavigation';
import { useTranslated } from '../hooks';

export const generateInitValues = (data) => {
  const initValues = {};
  data.forEach((element) => (initValues[element.name] = element.value));
  return initValues;
};

/**
 * @description Returns modified schema, specifically adding data for using the
 * address form field in Curious Panda
 *
 * @param {Array} schema - Array of objects that build the form schema
 * @param {Object} participant - Contains participant informations
 * @returns {Array} - modified schema
 */
export const formatSchema = (schema, participant) => [
  {
    input: schema.map((field) => {
      if (field.interface === 'address') {
        return { ...field, participant };
      }
      return field;
    }),
  },
];

const VerifyParticipantDataModal = ({ showModal, participant, verified }) => {
  const [schema, setSchema] = useState([]);
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    const { enrollment_identifier, participant_auth_token } = participant;
    if (enrollment_identifier && participant_auth_token) {
      api.participantInfo
        .data(enrollment_identifier, participant_auth_token)
        .then(({ data }) => {
          setInitValues(generateInitValues(data));
          setSchema([{ input: data }]);
        })
        .catch((error) => logError(error));
    }
  }, [participant, setInitValues, setSchema]);

  const handleClose = () => {
    api.cookie.set('verify_data', 'false', { expires: 1 });
    verified();
  };

  const handlSubmit = (form) => {
    const { enrollment_identifier, participant_auth_token } = participant;
    api.participantInfo
      .update(enrollment_identifier, participant_auth_token, form)
      .then(() => {
        handleClose();
      })
      .catch((error) => logError(error));
  };

  const submitText = useTranslated('components.common.submit');

  return (
    <StyledModal
      header={'Verify Information'}
      isOpen={showModal}
      closeModal={handleClose}
      style={{ overflow: 'scroll' }}
    >
      <CuriousPanda
        form="update_participant_data"
        schema={schema}
        initialValues={initValues}
        onSubmit={handlSubmit}
        enableReinitialize
        participant={participant}
      >
        {({ submitting }) => {
          return (
            <StyledButton
              style={{ marginTop: 20 }}
              isLoading={submitting}
              buttonStyle="achievement_text"
              id="t-submit_button"
              type="submit"
              disabled={submitting}
            >
              {submitText}
            </StyledButton>
          );
        }}
      </CuriousPanda>
    </StyledModal>
  );
};

VerifyParticipantDataModal.propTypes = {
  showModal: PropTypes.bool,
  participant: PropTypes.shape({
    enrollment_identifier: PropTypes.string.isRequired,
    participant_auth_token: PropTypes.string.isRequired,
  }),
  verified: PropTypes.func.isRequired,
};

export default VerifyParticipantDataModal;
