import { TRIAGE_ENDPOINT } from '../../constants';
import { RSAA, getJSON } from 'redux-api-middleware';
import { getPreviewToken } from '../../services';
import { REDUX_API_FAILURE } from '../errors/actions';
import { getQueryString, getStudySlug } from '../../utils';
import { LandingActionType } from '../../types/actions';
import { AnyAction } from 'redux';
import { LANDING_STATE } from '../../types/states';

const authHeader = (limited_token = '') => ({
  'Content-Type': 'application/json',
  'Preview-Token': getPreviewToken(),
  'Limited-Token': limited_token,
});

const landingUrl = () => {
  const url = new URL(
    `${TRIAGE_ENDPOINT}/api/v3/studies/${getStudySlug()}/landing`,
  );

  ['locale_override', 'utm_campaign'].forEach((param) => {
    let val = getQueryString(param);
    if (val) {
      url.searchParams.append(param, val);
    }
  });

  return url.toString();
};

const versionInfo = {
  billboard_branch_name: process.env.REACT_APP_BILLBOARD_BRANCH, //env var REACT_APP_BILLBOARD_BRANCH
  billboard_branch_tag: process.env.REACT_APP_BILLBOARD_TAG, //env var REACT_APP_BILLBOARD_TAG
  billboard_deployed_at: process.env.REACT_APP_BILLBOARD_RELEASE_DATE, //env var REACT_APP_BILLBOARD_RELEASE_DATE
  billboard_commit_sha1: process.env.REACT_APP_BILLBOARD_REVISION, //env var REACT_APP_BILLBOARD_REVISION
  circleci_job_url: process.env.REACT_APP_CIRCLE_BUILD_URL,
};

export const getLanding = () => {
  //Temporarily adding the log statement here to fetch version info for Billboard
  //@Todo work with Product and Design to find the right place in the GUI to show this info.
  /* eslint-disable no-console */
  console.log(versionInfo);
  return {
    [RSAA]: {
      endpoint: landingUrl(),
      method: 'GET',
      headers: authHeader(),
      types: [
        LandingActionType.REQUEST,
        {
          type: LandingActionType.RECEIVE,
          payload: (action: AnyAction, state: LANDING_STATE, res: Response) => {
            return getJSON(res).then((json: LANDING_STATE) => json);
          },
        },
        REDUX_API_FAILURE,
      ],
    },
  };
};
