// @ts-nocheck
import { Button } from '@evidation/form-elements';
import ProgressBar from './ProgressBar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { primaryFontFamily, secondaryFontFamily } from '../utils/brandHelpers';
import OptOut from '../routes/settings/components/optOutModal';
import { RECONSENT_REQUIRED } from '../routes/dashboard/constants';
import { useTranslation } from 'react-i18next';
import { SpaceBetween } from '@evidation/ui';

export const StyledButton = styled(Button)`
  ${({ theme, buttonStyle }) =>
    theme &&
    `
      ${
        theme.branding_json?.color_4 &&
        `background: ${
          buttonStyle === 'outline'
            ? 'transparent'
            : theme.branding_json.color_4
        } !important;`
      }
      font-family: ${
        theme.brand_name === 'PPMI'
          ? primaryFontFamily(theme, { fontWeight: 'bold' })
          : secondaryFontFamily(theme, { fontWeight: 'bold' })
      };
      ${
        theme.branding_json?.color_6 &&
        `color: ${
          buttonStyle === 'outline'
            ? theme.branding_json?.color_4 ?? 'inherit'
            : theme.branding_json.color_6
        } !important;`
      }
      border: 2px solid ${
        theme.branding_json?.color_4
          ? `${theme.branding_json.color_4}`
          : 'transparent'
      };
      &:hover {
        ${
          (theme.brand_name === 'Achievement' ||
            theme.branding_json?.color_8) &&
          (buttonStyle === 'outline'
            ? ['border-color', 'color']
            : ['background-color']
          )
            .map(
              (property) => `
                ${property}: ${
                theme.brand_name !== 'Achievement'
                  ? theme.branding_json.color_8
                  : '#0c4384'
              } !important;
              `,
            )
            .join('\n')
        }
      }
      &:disabled {
        color: #7d7e7f !important;
        background: #e6e6e6 !important;
      }
  `}
`;

const BillboardNavigation = ({
  valid,
  pristine,
  submitting,
  total = 0,
  disableNext,
  page = 0,
  initialValues = {},
  progress = false,
  enrollment_appx_time = 10,
  submit_label,
  next_label,
  back_label,
  decline_label,
  widthdraw_label,
  style = {},
  button_spacing = 10,
  children,
  buttonStyle = 'achievement',
  declinable = false,
  onDecline = () => {},
  onChangePage,
  disableSubmitWhenInvalid = false,
  meta,
  email,
  submitUserData,
  showProgress = true,
  history,
  nodes = [],
}) => {
  const [optOutModalOpen, setOptOutModalOpen] = useState(false);
  const { t } = useTranslation();
  const text = {
    submitLabel: t('components.billboardNavigation.submitLabel'),
    nextLabel: t('components.billboardNavigation.nextLabel'),
    backLabel: t('components.billboardNavigation.backLabel'),
    declineLabel: t('components.billboardNavigation.declineLabel'),
    withdrawLabel: t('components.billboardNavigation.withdrawLabel'),
  };
  // This addresses setting a default value using a translated string.
  submit_label = submit_label || text.submitLabel;
  next_label = next_label || text.nextLabel;
  back_label = back_label || text.backLabel;
  decline_label = decline_label || text.declineLabel;
  widthdraw_label = widthdraw_label || text.withdrawLabel;

  const reConsentNodes = nodes.filter(
    ({ type, status }) =>
      type === 'InformedConsent' && status === RECONSENT_REQUIRED,
  );

  const handleClickNext = () => onChangePage(+1);
  const handleClickPrevious = () => onChangePage(-1);
  let progressRemaining = 0;
  let calculatedProgress;
  const submitButtonDisabled =
    disableNext || submitting || (disableSubmitWhenInvalid && !valid);
  if (typeof progress === 'number') {
    if (total >= 1) {
      progressRemaining = 100 - progress;
      calculatedProgress = progress + (progressRemaining / total) * page - 5;
    } else {
      calculatedProgress = progress;
    }
  }

  return (
    <>
      <div
        style={{
          marginTop: 20,
          marginBottom: 60,
          ...style,
        }}
      >
        <SpaceBetween size={button_spacing}>
          {page === total && (
            <StyledButton
              isLoading={submitting}
              buttonStyle={buttonStyle}
              id="t-submit_button"
              data-test-id="submit_button"
              type="submit"
              disabled={submitButtonDisabled}
            >
              {submit_label}
            </StyledButton>
          )}
          {page === total && declinable && (
            <StyledButton
              isLoading={submitting}
              buttonStyle={buttonStyle}
              id="t-decline_button"
              data-test-id="decline_button"
              disabled={submitting}
              onClick={onDecline}
            >
              {decline_label}
            </StyledButton>
          )}
          {page === total && !declinable && reConsentNodes?.length > 0 && (
            <>
              <OptOut
                onClose={() => setOptOutModalOpen(false)}
                isOpen={optOutModalOpen}
                header={meta?.dashboard_withdrawal_modal_header || null}
                submitUserData={async (data) => {
                  await submitUserData(data);
                  history.push('/terminal_state');
                }}
                confirmEmail={email}
                copy={{
                  dashboard_withdrawal_modal_paragragh_1:
                    meta?.dashboard_withdrawal_modal_paragragh_1 || null,
                  dashboard_withdrawal_modal_reason:
                    meta?.dashboard_withdrawal_modal_reason || null,
                  dashboard_withdrawal_modal_paragragh_2:
                    meta?.dashboard_withdrawal_modal_paragragh_2 || null,
                  dashboard_withdrawal_link:
                    meta?.dashboard_withdrawal_link || null,
                }}
              />
              <StyledButton
                id="t-withdraw_from_study"
                onClick={() => setOptOutModalOpen(true)}
                isLoading={submitting}
                disabled={submitting}
                data-test-id="withdraw_button"
                buttonStyle="achievement_text"
              >
                {widthdraw_label}
              </StyledButton>
            </>
          )}
          {total > 0 && (
            <>
              {page !== total && (
                <StyledButton
                  buttonStyle={buttonStyle}
                  id="t-next_button"
                  disabled={disableNext || !valid}
                  onClick={handleClickNext}
                >
                  {next_label}
                </StyledButton>
              )}
              {page > 0 && (
                <StyledButton
                  buttonStyle={buttonStyle}
                  id="t-previous_button"
                  disabled={page === 0}
                  onClick={handleClickPrevious}
                >
                  {back_label}
                </StyledButton>
              )}
            </>
          )}
          {children}
        </SpaceBetween>
      </div>
      {showProgress && typeof calculatedProgress === 'number' && (
        <ProgressBar
          progress={calculatedProgress}
          enrollmentTime={enrollment_appx_time}
        />
      )}
    </>
  );
};

BillboardNavigation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  total: PropTypes.number,
  page: PropTypes.number,
  initialValues: PropTypes.object,
  progress: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  enrollment_appx_time: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  submit_label: PropTypes.string,
  next_label: PropTypes.string,
  back_label: PropTypes.string,
  style: PropTypes.object,
  button_spacing: PropTypes.number,
  buttonStyle: PropTypes.string,
  onDecline: PropTypes.func,
};

export default BillboardNavigation;
